import {Button, Modal, Toaster, Typography} from "@matillion/component-library";
import {useContext} from "react";
import {DesignerContext} from "../../DesignerContextProvider";
import Api from "../../../../api/Api";
import {useApi} from "@matillion/octo-react-util";

interface props {
    successAction: () => (void)
    cancelAction: () => (void)
    visible: boolean
}

export const NotSavedModal = ({successAction, cancelAction, visible}: props) => {
    let api = useApi<Api>()
    let {fileNames, state, setSavedState} = useContext(DesignerContext)!
    const { makeToast } = Toaster.useToaster()

    async function saveFileAndPerformAction() {
        if(fileNames.selected !== undefined) {
            try {
                await api.saveFile(fileNames.selected, state!!)
                setSavedState(state)
                makeToast({title: `Successfully saved '${fileNames.selected}'`, type: "success"})
                successAction()
            } catch (e) {
                makeToast({title: `Failed to save file '${fileNames.selected}'.`, type: "error"})
                cancelAction()
            }
        }
    }

    if(!visible) return <></>
    else return <Modal
        onCancel={() => {cancelAction()}}
        size={"large"}
        style={{display: "flex", flexDirection: "column", rowGap: "10px"}}
    >
        <Typography format="bcl" weight="bold">File has unsaved changes</Typography>
        <Typography>
            This action will use the most recent saved version. Do you want to save your changes before
            performing this action.
        </Typography>
        <div style={{display: "flex", flexDirection: "row", columnGap: "10px"}}>
            <Button text="Save and continue" onClick={() => {saveFileAndPerformAction()}} size="sm"/>
            <Button text="Do not save and continue" alt={"red"} onClick={() => {successAction()}} size="sm"/>
            <Button text="Cancel" alt={"secondary"} onClick={() => {cancelAction()}} size="sm"/>
        </div>
    </Modal>
}