import {Table as T} from "@matillion/component-library";
import {useContext, useEffect, useState} from "react";
import {DesignerContext} from "../../../DesignerContextProvider";
import {EnvironmentMetadata} from "../../../../../interfaces/EnvironmentMetadata";

const { Table, TableHead, TableBody, TableRow, TableCell } = T

export const EnvMetadataTable = () => {
    const {envs} = useContext(DesignerContext)!
    const [head, setHead] = useState<JSX.Element>()
    const [body, setBody] = useState<JSX.Element>()

    useEffect(() => {
        const env = envs.selected
        if(env === undefined) {
            setHead(undefined)
            setBody(undefined)
        } else if(env.warehouseProvider === "Snowflake") {
            setHead(snowflakeHead)
            setBody(genSnowflakeBody(env))
        }
    }, [envs.selected, setHead, setBody])

    return(
        <Table alt="normal">
            {head}
            {body}
        </Table>
    )
}

const snowflakeHead = <TableHead>
    <TableRow>
        <TableCell width="2">Warehouse Provider</TableCell>
        <TableCell width="2">Environment Name</TableCell>
        <TableCell width="2">Database Name</TableCell>
        <TableCell width="2">Account Identifier</TableCell>
        <TableCell width="2">User</TableCell>
        <TableCell width="2">Warehouse</TableCell>
    </TableRow>
</TableHead>

function genSnowflakeBody(env: EnvironmentMetadata) {
    return (
        <TableBody>
            <TableRow>
                <TableCell width="2">{env.warehouseProvider}</TableCell>
                <TableCell width="2">{env.envName}</TableCell>
                <TableCell width="2">{env.dbName}</TableCell>
                <TableCell width="2">{env.sfAccountIdentifier}</TableCell>
                <TableCell width="2">{env.sfUser}</TableCell>
                <TableCell width="2">{env.sfWarehouse}</TableCell>
            </TableRow>
        </TableBody>
    )
}