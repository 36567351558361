/**
 * This module was automatically generated by `ts-interface-builder`
 */
import * as t from "ts-interface-checker";
// tslint:disable:object-literal-key-quotes

export const DatabaseState = t.iface([], {
  "warehouseProvider": "string",
  "uuid": "string",
  "dataRetentionTimeInDays": "number",
  "schemas": t.array("Schema"),
  "ignoreSchema": t.array("string"),
});

export const Schema = t.iface([], {
  "uuid": "string",
  "name": "string",
  "transient": "boolean",
  "managedAccess": "boolean",
  "dataRetentionTimeInDays": "number",
  "comment": "string",
  "tables": t.array("Table"),
});

export const Table = t.iface([], {
  "uuid": "string",
  "name": "string",
  "comment": "string",
  "dataRetentionTimeInDays": "number",
  "columns": t.array("Column"),
  "foreignKeys": t.array("ForeignKeysConstraint"),
  "guiCoord": t.opt(t.union("Coord", "null")),
});

export const ForeignKeysConstraint = t.iface([], {
  "name": "string",
  "key": t.array("string"),
  "referenceTable": "DbObjectId",
  "referenceTableColumns": t.array("string"),
});

export const DbObjectId = t.iface([], {
  "database": t.opt(t.union("string", "null")),
  "schema": t.opt(t.union("string", "null")),
  "table": t.opt(t.union("string", "null")),
  "column": t.opt(t.union("string", "null")),
});

export const Coord = t.iface([], {
  "x": "number",
  "y": "number",
});

export const Column = t.iface([], {
  "uuid": "string",
  "name": "string",
  "typeName": "string",
  "unique": "boolean",
  "nullable": "boolean",
  "primary": "boolean",
});

const exportedTypeSuite: t.ITypeSuite = {
  DatabaseState,
  Schema,
  Table,
  ForeignKeysConstraint,
  DbObjectId,
  Coord,
  Column,
};
export default exportedTypeSuite;
