import {Edit} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import {Typography} from "@matillion/component-library";

interface Props {
    editable?: boolean,
    onSetEditable?: () => void,
    onAccept?: () => void,
    onCancel?: () => void,
    allowAccept?: boolean
}

export const EditControls = ({editable, onSetEditable, onAccept, onCancel, allowAccept = true} : Props) => {
    if(editable) return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div
                style={{display: "flex", flexDirection: "row", alignItems: "center"}}
                 onClick={allowAccept ? onAccept : undefined}
            >
                <DoneIcon  color={allowAccept ? "success" : "disabled"} fontSize="large" />
                <Typography>Accept</Typography>
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}} onClick={onCancel}>
                <CloseIcon  color="error" fontSize="large" />
                <Typography>Cancel</Typography>
            </div>
        </div>
    )
    else return <Edit style={{fontSize: 14, cursor: "pointer"}} onClick={onSetEditable}/>
}