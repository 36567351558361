import yaml from "js-yaml";
import {EnvironmentMetadata} from "../interfaces/EnvironmentMetadata";
import {DatabaseState} from "../interfaces/DatabaseState";
import {v4 as uuidv4} from 'uuid';
import {ApiError, EnvironmentEmptyError} from "./ApiError";
import {ErrorDto} from "../interfaces/ErrorDto";
import {BaseApi} from "@matillion/octo-react-util";

class Api extends BaseApi{

    async isAuthorised() {
        const response = await this.fetch(`/api/authorised`, {
            method: "GET",
        })
        return response.status === 200;
    }

    async addEnv(envMetadata: EnvironmentMetadata): Promise<boolean> {
        let response = await this.fetch(`/api/env/${envMetadata.envName}/metadata`, {
            method: "PUT",
            body: yaml.dump(envMetadata, {forceQuotes: true}),
            headers: {"content-type": "application/x-yaml"}
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to add environment '${envMetadata.envName}' to org '${this.orgId}': 
            ${this.getErrorMessage(await response.text())}`)
        else return true;
    }

    async deleteEnv(envName: string): Promise<boolean> {
        let response = await this.fetch(`/api/env/${envName}`, {
            method: "DELETE",
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to delete environment '${envName}' from org '${this.orgId}'.`)
        else return true;
    }

    async deploy(envName: any, fileName: string): Promise<boolean> {
        let response = await this.fetch(`/api/env/${envName}/state/`, {
            method: "PUT",
            body: fileName,
            headers: {"content-type": "text/plain"}
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to deploy file '${fileName}' to environment '${envName}' in org '${this.orgId}': ` +
                this.getErrorMessage(await response.text()))
        else return true;
    }

    async findPlan(envName: string, fileName: string) {
        let response = await this.fetch(`/api/env/${envName}/state/planner/readable`, {
            method: "POST",
            body: fileName,
            headers: {"content-type": "text/plain"}
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to find plan for org '${this.orgId}', env '${envName}' and file '${fileName}'.\n` +
                this.getErrorMessage(await response.text()))
        else return response.text()
    }

    async deleteFile(fileName: string) {
        let response = await this.fetch(`/api/stateFile/${fileName}`, {
            method: "DELETE",
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to delete file '${fileName}' in org '${this.orgId}'.`)
        else return true;
    }

    async saveFile(fileName: string, state: DatabaseState) {
        // check validity before save
        const validityResponse = await this.fetch(`/api/stateCheck`, {
            method: "POST",
            body: yaml.dump(state, {forceQuotes: true}),
            headers: {"content-type": "application/x-yaml"}
        })
        const errorResponse = yaml.load(await validityResponse.text()) as string[]
        if(errorResponse.length) throw new ApiError(errorResponse.join("\n"))

        // save file if all ok
        let response = await this.fetch(`/api/stateFile/${fileName}`, {
            method: "PUT",
            body: yaml.dump(state, {forceQuotes: true}),
            headers: {"content-type": "application/x-yaml"}
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to save file '${fileName}' in org '${this.orgId}'.`)
        else return true;
    }

    async copyFile(newFileName: string, otherFileName: string) {
        let response = await this.fetch(`/api/stateFile/${newFileName}`, {
            method: "POST",
            body: yaml.dump({
                fileName: otherFileName
            }),
            headers: {"content-type": "application/x-yaml"}
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to copy file '${otherFileName}' to '${newFileName}' in org '${this.orgId}'.`)
        else return yaml.load(await response.text()) as DatabaseState;
    }

    async getFile(fileName: string) {
        let response = await this.fetch(`/api/stateFile/${fileName}`, {
            method: "GET"
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to retrieve file '${fileName}' from org '${this.orgId}'.`)
        let env = await response.text()
        return yaml.load(env) as DatabaseState;
    }

    async createNewFile(fileName: string, warehouseProvider: string) {
        let response = await this.fetch(`/api/stateFile/${fileName}`, {
            method: "PUT",
            body: yaml.dump({
                warehouseProvider: warehouseProvider,
                uuid: uuidv4(),
                dataRetentionTimeInDays: "1",
                schemas: [],
            }, {forceQuotes: true}),
            headers: {"content-type": "application/x-yaml"}
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to create file '${fileName}' in '${this.orgId}'.`)
        else return true
    }

    async importState(fileName: string, envName: string, ignoreSchemas: string[]) {
        let response = await this.fetch(`/api/stateFile/${fileName}`, {
            method: "POST",
            body: yaml.dump({
                envName: envName,
                ignoreSchema: ignoreSchemas
            }),
            headers: {"content-type": "application/x-yaml"}
        })
        let env = await response.text()
        if(env.length === 0) throw new EnvironmentEmptyError(this.orgId, fileName, envName)
        if(response.status !== 200)
            throw new ApiError(`Failed to import state from ${envName} into file '${fileName} in org '${this.orgId}': ` +
                this.getErrorMessage(env))
        return yaml.load(env) as DatabaseState;
    }

    async getSchemaNames(envName: string) {
        let response = await this.fetch(`/api/env/${envName}/schemas`, {
            method: "GET"
        })
        let schemaNames = await response.text()
        if (schemaNames.length === 0) throw new EnvironmentEmptyError(this.orgId, undefined, envName)
        if (response.status !== 200)
            throw new ApiError(`Failed to get schema names for env '${envName} in ${this.orgId}': `
                + this.getErrorMessage(schemaNames))
        return yaml.load(schemaNames) as string[];
    }

    async getFileNames() {
        let response = await this.fetch(`/api/stateFile`, {
            method: "GET"
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to retrieve all file names from org '${this.orgId}.`)
        let envs = await response.text()
        return yaml.load(envs) as string[];
    }

    async getEnvironments() {
        let response = await this.fetch(`/api/env`, {
            method: "GET"
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to retrieve all environment names from org '${this.orgId}'.`)
        let envs = await response.text()
        return yaml.load(envs) as EnvironmentMetadata[];
    }

    async getEnvironmentLock(envName: string) {
        let response = await this.fetch(`/api/env/${envName}/lock`, {
            method: "GET"
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to check if environment '${envName}' in '${this.orgId}' is locked.`)
        return yaml.load(await response.text()) as boolean
    }

    async unlockEnvironment(envName: string) {
        let response = await this.fetch(`/api/env/${envName}/lock`, {
            method: "POST",
            body: "false"
        })
        if(response.status !== 200)
            throw new ApiError(`Failed to unlock environment '${envName}' in '${this.orgId}'.`)
        else return true
    }

    getErrorMessage(yamlText: string): string[] {
        return (yaml.load(yamlText) as ErrorDto).errorMessages;
    }
}

export default Api