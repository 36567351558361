import {DatabaseState, Table} from "../../../../interfaces/DatabaseState";
import classes from "./Node.module.scss";
import {useContext, useEffect, useRef, useState} from "react";
import Close from "@mui/icons-material/Close";
import {DesignerContext} from "../../DesignerContextProvider";
import {CollapsiblePanel, Tooltip} from "@matillion/component-library";

interface NodeProps {
    table: Table;
    onSelectTable: () => void;
    onRemoveTable: (table: Table, state: DatabaseState) => void;
}

export const Node = ({
                         table,
                         onSelectTable,
                         onRemoveTable,
                     }: NodeProps) => {
    const listRef = useRef<HTMLUListElement>(null);
    const {state, tables} = useContext(DesignerContext)!
    const [error, setError] = useState<string>()
    const [tableSelected, setTableSelected] = useState(false)

    useEffect(() => {
        if(tables.selected?.name === table.name) setTableSelected(true)
        else setTableSelected(false)
    }, [tables.selected, table.name])

    useEffect(() => {
        let colNames = table.columns.map((col) => col.name)
        if((new Set(colNames)).size !== colNames.length) setError("Duplicate column names")
        else if(table.columns.length === 0) setError("At least 1 column is required.")
        else setError(undefined)
    }, [table])

    const node = (
        <div
            className={classes.Node}
            onClick={onSelectTable}
            style={error ? errorStyle : (tableSelected ? selectedStyle :  undefined)}
        >
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <CollapsiblePanel title={table.name}>
                    <ul ref={listRef} style={{padding: 0}}>
                        {table.columns.map((col, _) => (
                            <li
                                style={{listStyle: "none", textAlign: "left", marginBottom: 10}}
                                key={col.name}
                            >
                                {`${col.name} (${col.typeName})`}
                            </li>
                        ))}
                    </ul>
                </CollapsiblePanel>
                <Close
                    style={{fontSize: 14, cursor: "pointer", color: "red"}}
                    onClick={() => {
                        if (state) onRemoveTable(table, state);
                    }}
                />
            </div>
        </div>
    );

    return error ? <Tooltip content={error}>{node}</Tooltip> : node
};

const errorStyle = {border: "2px solid red"}

const selectedStyle = {
    borderBottom: "2px solid grey",
    borderTop: "2px solid grey",
    borderRight: "2px solid grey",
}
