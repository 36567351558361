import {Modal, Typography} from "@matillion/component-library";
import {EnvironmentSelector} from "../../EnvironmentSelector";
import {EnvMetadataTable} from "./EnvMetadataTable";
import {DeleteEnvironmentButton} from "./DeleteEnvironmentButton";
import {AddEnvironmentButton} from "./AddEnvironmentButton";
import {UnlockEnvironmentButton} from "./UnlockEnvironmentButton";

interface Props {
    visible: boolean
    setVisible: (visible: boolean) => void
}

export const EnvironmentControlsModal = ({visible, setVisible}: Props) => {
    if (!visible) return <></>
    else return (
        <Modal
            onCancel={() => {
                setVisible(false)
            }}
            size={"large"}
            style={{display: "flex", flexDirection: "column", rowGap: "10px"}}
        >
            <Typography format="bcl" weight="bold">Environment Controls</Typography>
            <EnvironmentSelector/>
            <EnvMetadataTable/>
            <div style={{display: "flex", flexDirection: "row-reverse"}}>
                <AddEnvironmentButton/>
                <DeleteEnvironmentButton/>
                <UnlockEnvironmentButton/>
            </div>
        </Modal>
    )
}