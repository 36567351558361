import {DataGrid} from "@matillion/component-library";
import {Column} from "../../../../interfaces/DatabaseState";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {Checkbox} from '@mui/material';
import {Dispatch, useEffect, useReducer} from "react";

interface Props {
    columns: Column[],
    setSelectedCols: (cols: string[]) => void
}

type includedReducerAction = {type: "include", column: string} |
    {type: "order", column: string, direction: "up" | "down"}

export const ColumnSelector = ({columns, setSelectedCols}: Props) => {
    const [included, includedDispatch] =
        useReducer<(state: string[], action: includedReducerAction) => string[]>(includedReducer, [])

    function includedReducer(state: string[], action: includedReducerAction) {
        if(action.type === "include"){
            if(state.includes(action.column))
                return state.filter((item) => (item !== action.column))
            else
                return state.concat(action.column)
        } else if (action.type === "order") {
            let columnIndex = state.findIndex((column) => column === action.column)
            if(columnIndex !== -1){
                if(action.direction === "up" && columnIndex !== 0){
                    return state.map((column, index, columns) => {
                        if(index === columnIndex-1) return columns[columnIndex]
                        else if(index === columnIndex) return columns[columnIndex-1]
                        else return column
                    })
                } else if(action.direction === "down" && columnIndex !== state.length-1) {
                    return state.map((column, index, columns) => {
                        if(index === columnIndex) return columns[columnIndex+1]
                        else if(index === columnIndex+1) return columns[columnIndex]
                        else return column
                    })
                }
            }
        }
        return state
    }

    useEffect(() => {
        setSelectedCols(included)
    }, [setSelectedCols, included])

    let columnNames = included.concat(columns
        .map((column) => column.name)
        .filter((columnName) => !included.includes(columnName))
    )

    return <DataGrid
        columns={[
            {
                key: 'columnName',
                title: 'Column Name',
                sortable: false,
            },
            {
                key: 'included',
                title: 'Included',
                sortable: false,
                as: Checkbox
            },
            {
                key: 'order',
                title: 'Order',
                sortable: false,
                as: OrderControl
            }
        ]}
        rows={columnNames.map((columnName) => ({
            columnName: columnName,
            included: {
                checked: included.includes(columnName),
                onChange: () => includedDispatch({type: "include", column: columnName})
            },
            order: {
                includedDispatch: includedDispatch,
                columnName: columnName
            }
        }))}
    />
}

interface OrderControlProps {
    includedDispatch: Dispatch<includedReducerAction>,
    columnName: string
}

const OrderControl = ({includedDispatch, columnName}: OrderControlProps) => {
    return <div>
        <ArrowUpwardIcon
            fontSize={"large"}
            onClick={() => includedDispatch({type: "order", column: columnName, direction: "up"})}
        />
        <ArrowDownwardIcon
            fontSize={"large"}
            onClick={() => includedDispatch({type: "order", column: columnName, direction: "down"})}
        />
    </div>
}