import {Dispatch, SetStateAction} from "react";
import {AutoComplete} from "@matillion/component-library";
import {AutoCompleteItem} from "../../../interfaces/MclUtil";

enum WarehouseProvider {
    SNOWFLAKE= "Snowflake",
}

export const WarehouseProviderSelector = (props: { setWarehouseProvider:  Dispatch<SetStateAction<string>> }) => {
    return (
        <div>
            <label>
                <AutoComplete
                    availableItems={Object.values(WarehouseProvider).map((whProvider, index) => ({
                        id: index,
                        name: whProvider,
                    }))}
                    placeholder="Choose Warehouse Provider."
                    onChange={({target: { value: newValue },}: { target: AutoCompleteItem; }) => {
                        if (newValue) {
                            props.setWarehouseProvider(newValue!!.name)
                        }
                    }}
                />
            </label>
        </div>
    );
}