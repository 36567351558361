import {Validity} from "../interfaces/Validity";

export function isValidDbFabName(name: string): Validity {
    if(/^[a-zA-Z][\w-]{0,255}$/.test(name)) return {valid: true}
    else return {valid: false, message: "Input can only contain letters, '_' and '-'. It must also start with a letter."}
}

/**
 * Spec 1 - no spaces; 0 < length <= 255
 * @param s input
 */
export function isValidSpec1(s: string): Validity {
    if(s.length === 0 || s.length > 255)
        return {valid: false, message: "Input has to be longer than 0 and shorter than 255 characters."}
    if(/[\s]/.test(s))
        return {valid: false, message: "Input cannot container spaces."}
    else return {valid: true}
}

/**
 * typeSpec
 * @param s input
 */
export function isValidTypeSpec(s: string): Validity {
    return {valid: true}
}