import {Button, Toaster} from "@matillion/component-library";
import {useContext, useState} from "react";
import {DesignerContext} from "../DesignerContextProvider";
import {ApiError} from "../../../api/ApiError";
import {useApi} from "@matillion/octo-react-util";
import Api from "../../../api/Api";

export const SaveControls = () => {
    let api = useApi<Api>()
    let { fileNames, state, setState, savedState, setSavedState } = useContext(DesignerContext)!
    let [loading, setLoading] = useState(false)
    const { makeToast } = Toaster.useToaster()

    async function saveFile(fileName: string) {
        try {
            setLoading(true)
            await api.saveFile(fileName, state!)
            setSavedState(state)
            makeToast({title: `Successfully saved '${fileNames.selected}'`, type: "success"})
        } catch (e) {
            if (e instanceof ApiError) {
                makeToast({
                    title: e.message.split('\n').map((line) => <p>{line}</p>), type: "error", action: {
                        text: "Try Again",
                        onClick: () => saveFile(fileName)
                    }
                })
            }
        } finally {
            setLoading(false)
        }
    }

    return <div>
        <Button
            alt="primary"
            waiting={loading}
            disabled={state === undefined || state === savedState}
            fontWeight="bold"
            onClick={() => {if(fileNames.selected) saveFile(fileNames.selected)}}
            text="Save Changes"
        />
        <Button
            alt="text"
            fontWeight="normal"
            onClick={() => {setState(savedState)}}
            text="Reset state back to last saved state"
        />
    </div>
}