import {Button, Toaster} from "@matillion/component-library";
import {useContext, useState} from "react";
import {DesignerContext} from "../../DesignerContextProvider";
import {NotSavedModal} from "./NotSavedModal";
import {ApiError} from "../../../../api/ApiError";
import Api from "../../../../api/Api";
import {useApi} from "@matillion/octo-react-util";

export const DeployButton = () => {
    const [loading, setLoading] = useState(false);
    let {fileNames, state, savedState, envs} = useContext(DesignerContext)!
    let api = useApi<Api>()
    const { makeToast } = Toaster.useToaster()
    let [fileNotSavedModalVisible, setFileNotSavedModalVisible] = useState(false)

    let fileNotSavedModal = <NotSavedModal
        successAction={() => {setFileNotSavedModalVisible(false); deploy()}}
        cancelAction={() => setFileNotSavedModalVisible(false)}
        visible={fileNotSavedModalVisible}
    />

    async function deploy() {
        async function nestedDeploy(envName: string, fileName: string) {
            setLoading(true)
            try {
                await api.deploy(envName, fileName)
                makeToast({title: "Successfully Deployed", type: "success"})
            } catch (e) {
                if (e instanceof ApiError) {
                    makeToast({
                        title: e.message, type: "error", action: {
                            text: "Try Again",
                            onClick: () => nestedDeploy(envName, fileName)
                        }
                    })
                }
            } finally {
                setLoading(false)
            }
        }
        if(envs.selected !== undefined && fileNames.selected !== undefined)
            nestedDeploy(envs.selected.envName, fileNames.selected)
    }

    return (
        <>
            {fileNotSavedModal}
            <Button
                waiting={loading}
                disabled={fileNames.selected === undefined || envs.selected === undefined}
                size="sm"
                onClick={() => {
                    if (state !== savedState) setFileNotSavedModalVisible(true)
                    else deploy()
                }}
                text="Deploy"
                alt={"red"}
            />
        </>
    )
}