import React, {createContext, Dispatch, SetStateAction, useState} from "react";
import {DatabaseState, Schema, Table} from "../../interfaces/DatabaseState";
import {EnvironmentMetadata} from "../../interfaces/EnvironmentMetadata";
import {UpdatingSelection, useUpdatingSelection} from "../../common/UpdatingSelection";
import {useApi, useOrgId} from "@matillion/octo-react-util";
import Api from "../../api/Api";

export const DesignerContext = createContext<{
    orgId: string,
    fileNames: UpdatingSelection<string>,
    state: DatabaseState | null,
    setState: Dispatch<SetStateAction<DatabaseState | null>>
    savedState: DatabaseState | null,
    setSavedState: Dispatch<SetStateAction<DatabaseState | null>>
    schemas: UpdatingSelection<Schema>
    tables: UpdatingSelection<Table>
    envs: UpdatingSelection<EnvironmentMetadata>
    resetDesigner: (params: ResetDesignerParameters) => void
} | null>(null);


export interface ResetDesignerParameters {
    fileNameIndex?: number,
    fileName?: string,
    state?: DatabaseState | null,
    savedState?: DatabaseState | null,
    schemaIndex?: number,
    schema?: Schema,
    tableIndex?: number
    table?: Table
}

interface Props {
    children: React.ReactElement,
}

export const DesignerContextProvider = ({children}: Props) => {
    const api = useApi<Api>()
    const orgId = useOrgId()

    const fileNames = useUpdatingSelection<string>(
        async () => await api.getFileNames(),
        [api]
    )

    const [state, setState] = useState<DatabaseState|null>(null)
    const [savedState, setSavedState] = useState<DatabaseState|null>(null)

    const schemas = useUpdatingSelection<Schema>(() => {if(state !== null) return state.schemas}, [state, state?.schemas])
    const tables = useUpdatingSelection<Table>(() => {
        if(schemas.selected !== undefined) return schemas.selected.tables
    }, [state, schemas.selected, schemas.selected?.tables])

    const envs = useUpdatingSelection(
        async () => await api.getEnvironments(),
        [api]
    )

    const designerContextValue = {
        orgId,
        fileNames,
        state,
        setState,
        savedState,
        setSavedState,
        schemas,
        tables,
        envs,
        resetDesigner
    }

    function resetDesigner ({fileNameIndex,fileName,state,savedState,schemaIndex,schema,tableIndex,table}: ResetDesignerParameters) {
        if(fileNameIndex !== undefined) fileNames.setSelectedIndex(fileNameIndex)
        else if(fileName !== undefined) fileNames.setSelectedItem(fileName)
        else fileNames.setSelectedIndex(undefined)

        if(state !== undefined) setState(state); else setState(null)
        if(savedState !== undefined) setSavedState(savedState); else setSavedState(null)

        if(schemaIndex !== undefined) schemas.setSelectedIndex(schemaIndex)
        else if(schema !== undefined) schemas.setSelectedItem(schema)
        else schemas.setSelectedIndex(undefined)

        if(tableIndex !== undefined) tables.setSelectedIndex(tableIndex)
        if(table !== undefined) tables.setSelectedItem(table)
        else tables.setSelectedIndex(undefined)
    }

    return (
        <DesignerContext.Provider value={designerContextValue}>
            {children}
        </DesignerContext.Provider>
    )
}