import {
    Column as ColumnTIface,
    DatabaseState as DatabaseStateTIface,
    Schema as SchemaTIface,
    Table as TableTIface
} from "../../../../interfaces/DatabaseState-ti";
import {TIface} from "ts-interface-checker";
import {CompletionContext} from "@codemirror/autocomplete";
import {v4 as uuidv4} from "uuid";
import CodeMirror from "@uiw/react-codemirror/esm";
import * as React from "react";
import {StreamLanguage} from "@codemirror/stream-parser";
import {yaml as yamlStreamParser} from "@codemirror/legacy-modes/mode/yaml";

interface DBFabYamlEditorProps {
    value: string,
    onChange: (value: string) => void
}

export const DBFabYamlEditor = ({value, onChange}: DBFabYamlEditorProps) => {
    let yamlLanguage = StreamLanguage.define(yamlStreamParser)
    let autocomplete = yamlLanguage.data.of({
        autocomplete: dbFabAutoComplete
    })

  return <CodeMirror
      value={value}
      autoFocus={true}
      placeholder={placeHolderText}
      extensions={[autocomplete, yamlLanguage]}
      onChange={onChange}
      indentWithTab={true}
  />
}

function dbFabAutoComplete(context: CompletionContext) {
    const options = []
    const word = context.matchBefore(/\w*/)
    const line = context.matchBefore(/.*/)
    const yaml = context.state.doc
    if (!word || !line || (word.from === word.to && !context.explicit)) return null

    const indent = getIndent(line.text)
    const lineNum = yaml.lineAt(context.pos).number
    for(let i=lineNum-1; i>0; i--){
        const currentLine = yaml.line(i).text
        const lineIndent = getIndent(currentLine)
        if(lineIndent<indent){
            if(currentLine.includes("columns"))         options.push(...AC_COL_OPTIONS)
            else if(currentLine.includes("tables"))     options.push(...AC_TBL_OPTIONS)
            else if(currentLine.includes("schemas"))    options.push(...AC_SCH_OPTIONS)
            break
        }
    }
    if(options.length === 0) options.push(...AC_DB_OPTIONS)

    const uuidOption = options.find((option) => option.label === "uuid")
    if(uuidOption) uuidOption.apply = `uuid: '${uuidv4()}'`

    return {
        from: word.from,
        options: options
    }
}

function getIndent(line: string) {
    return line.replace("-", " ").search(/\S|$/)
}

function tIfaceToAcOption(tIface: TIface) {
    return tIface.props.map((prop) => ({
        label: prop.name,
        apply: `${prop.name}: `
    }))
}

const AC_DB_OPTIONS = tIfaceToAcOption(DatabaseStateTIface)
const AC_SCH_OPTIONS = tIfaceToAcOption(SchemaTIface)
const AC_TBL_OPTIONS = tIfaceToAcOption(TableTIface)
const AC_COL_OPTIONS = tIfaceToAcOption(ColumnTIface)

const placeHolderText =
    "Enter YAML definition of database.\n\n" +
    "Example:\n" +
    "warehouseProvider: 'Snowflake'\n" +
    "uuid: '00000000-0000-0000-0000-000000000000'\n" +
    "comment: 'Example comment'\n" +
    "dataRetentionTimeInDays: 1\n" +
    "schemas: []\n" +
    "ignoreSchema: []"