import {Dispatch, FormEvent, SetStateAction, useContext, useState} from "react";
import {AutoComplete, Button, Card, Modal, Toaster, Typography} from "@matillion/component-library";
import {DesignerContext} from "../../DesignerContextProvider";
import {AutoCompleteItem} from "../../../../interfaces/MclUtil";
import {WarehouseProviderSelector} from "../WarehouseProviderSelector";
import {ApiError, EnvironmentEmptyError} from "../../../../api/ApiError";
import {DatabaseState} from "../../../../interfaces/DatabaseState";
import {EnvironmentSelector} from "../EnvironmentSelector";
import {IgnoreSchemaSelector} from "./IgnoreSchemaSelector";
import {useSharedInputValidity, ValidatedInput} from "../../../common/ValidatedInput";
import {useApi} from "@matillion/octo-react-util";
import Api from "../../../../api/Api";


interface Props {
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
}

export const NewFileModal = ({visible, setVisible}: Props) => {
    const api = useApi<Api>()
    const [loading, setLoading] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const [newFileName, setNewFileName] = useState<string>('')
    const [newFileWarehouseProvider, setNewFileWarehouseProvider] = useState<string>('')
    const [fileToCopy, setFileToCopy] = useState<string>('')
    const {envs, resetDesigner, fileNames} = useContext(DesignerContext)!
    const { makeToast } = Toaster.useToaster()
    const [ignoreSchemas, setIgnoreSchemas] = useState<string[]>()
    const {allInputsValid, invalidInputDispatch} = useSharedInputValidity()

    function resetModal() {
        setPageIndex(0)
        setNewFileName('')
        setNewFileWarehouseProvider('')
        invalidInputDispatch({reset: true})
        setVisible(false)
    }

    async function createNewEmptyFile(fileName: string, warehouseProvider: string) {
        setLoading(true)
        try {
            await api.createNewFile(fileName, warehouseProvider)
            let state = await api.getFile(fileName)
            addAndOpenFile(fileName, state)
        } catch (e) {
            if (e instanceof ApiError) {
                makeToast({
                    title: e.message,
                    type: "error",
                    action: {text: "Try Again", onClick: () => createNewEmptyFile(fileName, warehouseProvider)}
                })
            }
        }
        setLoading(false)
    }

    async function createNewImportedFile(fileName: string, envName: string, ignoreSchemas: string[]) {
        setLoading(true)
        try {
            let state = await api.importState(fileName, envName, ignoreSchemas)
            addAndOpenFile(fileName, state)
        } catch (e) {
            if (e instanceof EnvironmentEmptyError && envs.selection) {
                const warehouseProvider =
                    envs.selection[envs.selection.findIndex((env) => (env.envName === envName))].warehouseProvider
                makeToast({
                    title: e.message,
                    type: "error",
                    action: {
                        text: `Create empty file called '${fileName}'`,
                        onClick: () => createNewEmptyFile(fileName, warehouseProvider)
                    }
                })
            } else if (e instanceof ApiError) makeToast({title: e.message, type: "error",})
        }
        setLoading(false)
    }

    async function createNewCopiedFile(newFileName: string, otherFileName: string) {
        setLoading(true)
        try {
            let state = await api.copyFile(newFileName, otherFileName)
            addAndOpenFile(newFileName, state)
        } catch (e) {
            if (e instanceof EnvironmentEmptyError && envs.selection) {
                makeToast({
                    title: e.message,
                    type: "error",
                    action: {
                        title: e.message,
                        type: "error",
                        action: {text: "Try Again", onClick: () => createNewCopiedFile(newFileName, otherFileName)}
                    }
                })
            }
        }
        setLoading(false)
    }

    function addAndOpenFile(fileName: string, state: DatabaseState) {
        let newFileIndex = fileNames.selection?.length ? fileNames.selection.length : 0
        fileNames.addItem(fileName)
        resetDesigner({fileNameIndex: newFileIndex, state: state, savedState: state})
        makeToast({ title: `Successfully Created File ${fileName}`, type: 'success' })
        resetModal()
    }

    const index0Modal = visible && pageIndex === 0 ?
        <Modal
            onCancel={() => resetModal()}
            size={"large"}
            style={{display: "flex", flexDirection: "column", rowGap: "10px"}}
        >
            <Typography format="bcl" weight="bold">How would you like to make the new file?</Typography>
            <div style={{display: "flex", flexDirection: "row", columnGap: "10px"}}>
                <Card style={{flex: 1}} onClick={()=> setPageIndex(1)}>
                    <Typography format="bcl" weight="bold">Create New Empty File</Typography><br/>
                    <Typography>Create a file with no schemas or tables.</Typography>
                </Card>
                <Card style={{flex: 1}} onClick={()=> setPageIndex(2)}>
                    <Typography format="bcl" weight="bold">Create New File From Environment</Typography><br/>
                    <Typography>
                        Create a file that is pre-populated with objects that already exists in the environment.
                    </Typography>
                </Card>
                <Card style={{flex: 1}} onClick={()=> setPageIndex(3)}>
                    <Typography format="bcl" weight="bold">Make Copy of File</Typography><br/>
                    <Typography>
                        Make a copy of a file that already exists in your org.
                    </Typography>
                </Card>
            </div>
        </Modal>
        : <></>

    const emptyNewFileModal = visible && pageIndex === 1 ?
        <Modal
            onCancel={() => {if(!loading) resetModal()}}
            size={"large"}
            style={{display: "flex", flexDirection: "column", rowGap: "10px"}}
        >
            <Typography format="bcl" weight="bold">Create New Empty File</Typography>
            <ValidatedInput
                valueName={"File name"}
                placeholder="File Name..."
                onChange={(event: FormEvent<HTMLInputElement>) => {setNewFileName(event.currentTarget.value)}}
                invalidInputDispatch={invalidInputDispatch}
                invalidInputs={fileNames.selection}
            />
            <WarehouseProviderSelector setWarehouseProvider={setNewFileWarehouseProvider}/>
            <Button
                disabled={!allInputsValid || newFileWarehouseProvider === ''}
                waiting={loading}
                iconAlign="right"
                onClick={() => createNewEmptyFile(newFileName, newFileWarehouseProvider)}
                size="md"
                text="Create New File"
            />
        </Modal> : <></>

    const importNewFileModal = visible && pageIndex === 2 ?
        <Modal
            onCancel={() => {if(!loading) resetModal()}}
            size={"large"}
            style={{display: "flex", flexDirection: "column", rowGap: "10px"}}
        >
            <Typography format="bcl" weight="bold">Create New File From Environment</Typography>
            <ValidatedInput
                valueName={"File name"}
                placeholder="File Name..."
                onChange={(event: FormEvent<HTMLInputElement>) => {setNewFileName(event.currentTarget.value)}}
                invalidInputDispatch={invalidInputDispatch}
                invalidInputs={fileNames.selection}
            />
            <EnvironmentSelector/>
            {envs.selected ? <IgnoreSchemaSelector setIgnoreSchemas={setIgnoreSchemas}/> : null}
            <Button
                disabled={!allInputsValid || envs.selected === undefined || ignoreSchemas === undefined}
                waiting={loading}
                iconAlign="right"
                onClick={() => {
                    if (ignoreSchemas && envs.selected)
                        createNewImportedFile(newFileName, envs.selected.envName, ignoreSchemas)}
                }
                size="md"
                text="Create New File"
            />
        </Modal> : <></>

    const copyFileModal = visible && pageIndex === 3 ?
        <Modal
            onCancel={() => {if(!loading) resetModal()}}
            size={"large"}
            style={{display: "flex", flexDirection: "column", rowGap: "10px"}}
        >
            <Typography format="bcl" weight="bold">Copy File</Typography>
            <ValidatedInput
                valueName={"File name"}
                placeholder="New File Name..."
                onChange={(event: FormEvent<HTMLInputElement>) => {setNewFileName(event.currentTarget.value)}}
                invalidInputDispatch={invalidInputDispatch}
                invalidInputs={fileNames.selection}
            />
            <AutoComplete
                loading={fileNames.loading}
                disabled={!fileNames.selection?.length}
                availableItems={fileNames.selection?.map(
                    (FileName, index) =>({id: index, name: FileName})
                ) ?? []}
                value={{
                    id: fileNames.selection?.findIndex((chosen: string) => fileToCopy === chosen),
                    name: fileToCopy
                }}
                placeholder={fileNames.selection?.length ? "Choose File" : "No files to copy"}
                onChange={({target: {value: newValue},}: { target: AutoCompleteItem }) => {
                    if (newValue) setFileToCopy(newValue.name)
                }}
            />
            <Button
                disabled={!allInputsValid || fileToCopy === ''}
                waiting={loading}
                iconAlign="right"
                onClick={() => {
                        createNewCopiedFile(newFileName, fileToCopy)}}
                size="md"
                text="Copy File"
            />
        </Modal> : <></>

    return <>{index0Modal}{importNewFileModal}{emptyNewFileModal}{copyFileModal}</>
}