import {useContext, useState} from "react";
import {DesignerContext} from "../../DesignerContextProvider";
import {AutoComplete, Button, Toaster, Typography} from "@matillion/component-library";
import {AutoCompleteItem} from "../../../../interfaces/MclUtil";
import {NewFileModal} from "./NewFileModal";
import {ApiError} from "../../../../api/ApiError";
import {useApi} from "@matillion/octo-react-util";
import Api from "../../../../api/Api";

export const FileSelector = () => {
    let [newFileModalVisible, setNewFileModalVisible] = useState<boolean>(false)
    let [deleting, setDeleting] = useState(false)
    let [openingFile, setOpeningFile] = useState(false)
    let {fileNames, resetDesigner} = useContext(DesignerContext)!
    let api = useApi<Api>()
    const { makeToast } = Toaster.useToaster()

    async function openFile(fileName: string) {
        setOpeningFile(true)
        resetDesigner({})
        try {
            let state = await api.getFile(fileName)
            resetDesigner({fileName: fileName, state: state, savedState: state})
        } catch (e) {
            if (e instanceof ApiError) {
                makeToast({
                    title: e.message, type: "error", action: {
                        text: "Try Again",
                        onClick: () => openFile(fileName)
                    }
                })
            }
        }
        setOpeningFile(false)
    }

    async function deleteFile(fileName: string) {
        setDeleting(true)
        try {
            await api.deleteFile(fileName)
            makeToast({title: `Successfully Deleted File ${fileName}`, type: 'success'})
            fileNames.removeItem(fileName)
            resetDesigner({})
        } catch (e) {
            if (e instanceof ApiError) {
                makeToast({
                    title: e.message, type: "error", action: {
                        text: "Try Again",
                        onClick: () => deleteFile(fileName)
                    }
                })
            }
        }
        setDeleting(false)
    }

    return (
        <div style={{display: "flex", flexDirection: "column", rowGap: "10px"}}>
            <Typography format={"bcl"}>File Name</Typography>
            <AutoComplete
                loading={fileNames.loading || openingFile}
                disabled={!fileNames.selection?.length}
                availableItems={fileNames.selection?.map((FileName, index) => ({id: index, name: FileName})) ?? []}
                value={fileNames.selected ? {id: fileNames.selectedIndex, name: fileNames.selected} : null}
                placeholder="Choose File"
                onChange={({target: {value: newValue},}: { target: AutoCompleteItem }) => {
                    if (newValue && newValue.name !== fileNames.selected)
                        openFile(newValue.name)
                }}
            />
            <Button
                size="sm"
                alt="blue"
                onClick={() => {setNewFileModalVisible(true)}}
                text="Create New File"
            />
            <Button
                disabled={fileNames.selected === undefined}
                waiting={deleting}
                iconAlign="right"
                onClick={() => {if(fileNames.selected)
                    deleteFile(fileNames.selected)}}
                size="sm"
                alt={"red"}
                text="Delete File"
            />
            <NewFileModal visible={newFileModalVisible} setVisible={setNewFileModalVisible}/>
        </div>
    )

}