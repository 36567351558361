import {Typography} from "@matillion/component-library";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useContext} from "react";
import {DesignerContext} from "../DesignerContextProvider";

interface Instruction {
  instruction: string | string[]
  done?: () => boolean
}

function toTable(instructions: Instruction[]) {
  return (
      <table>
        {instructions.map((instruction: Instruction, index: number) => {
              if (typeof instruction.instruction === 'string')
                return (
                    <tr>
                      <td><Typography>{(index+1) + "."}</Typography></td>
                      <td><Typography>{instruction.instruction}</Typography></td>
                        {instruction.done !== undefined && instruction.done() ? <td><CheckCircleIcon/></td> : <></>}
                    </tr>
                )
              else return (
                  instruction.instruction.map((subInstruction: string, subIndex: number) => {
                    return (
                        <tr>
                          <td><Typography>{(index+1) + "." + (subIndex+1) + "."}</Typography></td>
                          <td><Typography>{subInstruction}</Typography></td>
                        </tr>
                    )
                  })
              )
            }
        )}
      </table>
  )
}

export const Instructions = () => {
  const {fileNames} = useContext(DesignerContext)!

  const instructions: Instruction[] = [
    {instruction: "Chose a File", done: () => fileNames.selected !== undefined},
    {instruction: ["Directly Edit Yaml in second tab", "Select or create a schema and edit using GUI tab"]}
  ]
  const table = toTable(instructions)
  return (
      <div style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"}}
      >
          <Typography format="bcl" weight="bold">Instructions</Typography><br/>
          {table}
      </div>
  )
}