import {useContext, useEffect, useState} from "react";
import {DesignerContext} from "../../DesignerContextProvider";
import {DatabaseState} from "../../../../interfaces/DatabaseState";
import produce from "immer";
import {v4 as uuidv4} from "uuid";
import {Button, DataGrid, Toaster} from "@matillion/component-library";
import {EditableField} from "./EditableField";
import {EditControls} from "./EditControls";
import CloseIcon from "@mui/icons-material/Close";
import {Divider} from "@mui/material";
import {EditableBool} from "./EditableBool";
import {isValidTypeSpec} from "../../../../common/InputValidation";
import {useSharedInputValidity} from "../../../common/ValidatedInput";

export const ColumnTab = () => {
    let {state, setState, tables, schemas} = useContext(DesignerContext)!
    const [editableRowIndex, setEditableRowIndex] = useState<number>()
    const [newColumnName, setNewColumnName] = useState<string>()
    const [newColumnType, setNewColumnType] = useState<string>()
    const [newUnique, setNewUnique] = useState<boolean>()
    const [newNullable, setNewNullable] = useState<boolean>()
    const [newPrimary, setNewPrimary] = useState<boolean>()
    const {allInputsValid, invalidInputDispatch} = useSharedInputValidity()
    const { makeToast } = Toaster.useToaster()

    const updateState = (
        columnIndex: number,
        newColumnName: string,
        newColumnType: string,
        newUnique: boolean,
        newNullable: boolean,
        newPrimary: boolean,
        state: DatabaseState,
    ) => {
        const newState = produce(state, (draft) => {
            if(schemas.selectedIndex !== undefined && tables.selectedIndex !== undefined && draft !== null) {
                let column =  draft.schemas[schemas.selectedIndex].tables[tables.selectedIndex].columns[columnIndex]
                column.name = newColumnName
                column.typeName = newColumnType
                column.unique = newUnique
                column.nullable = newNullable
                column.primary = newPrimary
            }
        })
        setState(newState)
    }

    const removeColumn = (columnIndex: number, state: DatabaseState) => {
        const newState = produce(state, (draft) => {
            if(schemas.selectedIndex !== undefined && tables.selectedIndex !== undefined && draft !== null) {
                let table = draft.schemas[schemas.selectedIndex].tables[tables.selectedIndex]
                const colName = table.columns[columnIndex].name

                let okToRemove = !table.foreignKeys.some((fk) => fk.key.includes(colName))
                if(okToRemove) okToRemove = draft.schemas.every((sch) =>
                    sch.tables.every((tbl) =>
                        tbl.foreignKeys.every((fk) => {
                            return (fk.referenceTable.schema !== schemas.selected?.name && fk.referenceTable.schema !== tables.selected?.name) ||
                                !fk.referenceTableColumns.includes(colName)
                            }
                        )
                    )
                )

                if(okToRemove) table.columns = table.columns.filter((_, index) => index !== columnIndex)
                else makeToast({title: "Unable to remove column because a foreign key constraint relies on the column. " +
                        "Remove the foreign key constraint before removing the column.", type: 'error'})
            }
        })
        setState(newState)
    }

    const addColumn = (state: DatabaseState) => {
        const newState = produce(state, (draft) => {
            if(schemas.selectedIndex !== undefined && tables.selectedIndex !== undefined && draft !== null) {
                const columns = draft.schemas[schemas.selectedIndex].tables[tables.selectedIndex].columns
                const columnsNames = columns.map((col) => col.name)
                let newColumnName
                let i = columnsNames.length
                do {
                    i++
                    newColumnName = `C${i}`
                } while (columnsNames.includes(newColumnName));
                columns.push(
                    {
                        uuid: uuidv4(),
                        name: newColumnName,
                        typeName: "VARCHAR(16777216)",
                        unique: false,
                        nullable: true,
                        primary: false
                    }
                )
            }
        })
        setState(newState)
    }

    const changeEditableRow = (index: number) => {
        setEditableRowIndex(index)
        setNewColumnName(tables.selected?.columns[index].name)
        setNewColumnType(tables.selected?.columns[index].typeName)
        setNewUnique(tables.selected?.columns[index].unique)
        setNewNullable(tables.selected?.columns[index].nullable)
        setNewPrimary(tables.selected?.columns[index].primary)
    }

    function resetEditing() {
        setEditableRowIndex(undefined)
        setNewColumnType(undefined)
        setNewColumnName(undefined)
        setNewUnique(undefined)
        setNewNullable(undefined)
        setNewPrimary(undefined)
        invalidInputDispatch({reset: true})
    }

    useEffect(resetEditing, [tables.selected, invalidInputDispatch])

    return !tables.selected ? null : (
        <>
            <DataGrid
                columns={[
                    {
                        key: 'columnName',
                        title: 'Column Name',
                        sortable: false,
                        as: EditableField
                    },
                    {
                        key: 'columnType',
                        title: 'Column Type',
                        sortable: false,
                        as: EditableField
                    },
                    {
                        key: 'unique',
                        title: 'Unique',
                        sortable: false,
                        as: EditableBool
                    },
                    {
                        key: 'nullable',
                        title: 'Nullable',
                        sortable: false,
                        as: EditableBool
                    },
                    {
                        key: 'primary',
                        title: 'Primary',
                        sortable: false,
                        as: EditableBool
                    },
                    {
                        key: 'edit',
                        title: 'Edit',
                        sortable: false,
                        as: EditControls,
                    },
                    {
                        key: 'remove',
                        title: 'Remove',
                        sortable: false,
                        as: CloseIcon,
                    }
                ]}
                rows={
                    tables.selected.columns.map((column, index) => {
                        const editable = editableRowIndex === index
                        return {
                            columnName: {
                                editable: editable,
                                value: index === editableRowIndex ? newColumnName : column.name,
                                onChange: setNewColumnName,
                                validatedInputProps : {
                                    valueName: "Column Name",
                                    invalidInputs: tables.selected?.columns.map((col) => col.name)
                                        .filter((col) => col !== column.name),
                                    invalidInputDispatch: invalidInputDispatch
                                }
                            },
                            columnType: {
                                editable: editable,
                                value: index === editableRowIndex ? newColumnType : column.typeName,
                                onChange: setNewColumnType,
                                validatedInputProps : {
                                    valueName: "Column Type",
                                    isValid: isValidTypeSpec,
                                    invalidInputDispatch: invalidInputDispatch
                                }
                            },
                            unique: {
                                value: index === editableRowIndex ? newUnique : column.unique,
                                editable: editable,
                                onChange: setNewUnique,
                                disabled: newPrimary && !newUnique
                            },
                            nullable: {
                                value: index === editableRowIndex ? newNullable : column.nullable,
                                editable: editable,
                                onChange: setNewNullable,
                                disabled: newPrimary && !newNullable
                            },
                            primary: {
                                value: index === editableRowIndex ? newPrimary : column.primary,
                                editable: editable,
                                onChange: setNewPrimary,
                                disabled: !newPrimary && (newUnique || newNullable)
                            },
                            edit: {
                                editable: editable,
                                onSetEditable: () => changeEditableRow(index),
                                onAccept: () => {
                                    if (newColumnName && newColumnType && newUnique !== undefined &&
                                        newNullable !== undefined && newPrimary !== undefined && state) {
                                        updateState(
                                            index,
                                            newColumnName,
                                            newColumnType,
                                            newUnique,
                                            newNullable,
                                            newPrimary,
                                            state
                                        )
                                        resetEditing()
                                    }
                                },
                                onCancel: resetEditing,
                                allowAccept: allInputsValid
                            },
                            remove: {
                                color: "error",
                                fontSize: "large",
                                onClick: () => {if (state) removeColumn(index, state)}
                            },
                        }
                    })
                }
            />
            <Divider/>
            <Button
                size="sm"
                text="Add Column"
                onClick={() => {if(state) addColumn(state)}}
            />
        </>
    );
}