import {Typography} from "@matillion/component-library";
import {Checkbox} from "@mui/material";
import {ChangeEvent} from "react";

interface Props {
    editable?: boolean,
    value: boolean,
    onChange?: (value: boolean) => void
    disabled?: boolean
}

export const EditableBool = ({editable = false, value, onChange, disabled = false}: Props) => {
    if(editable)
        return <Checkbox
            checked={value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if(onChange) onChange(event.target.checked)
            }}
            disabled={disabled}
        />
    else
        return <Typography>{value ? "Yes" : "No"}</Typography>
}