import {Typography} from "@matillion/component-library";
import {ValidatedInput, ValidatedInputProps} from "../../../common/ValidatedInput";

interface Props {
    editable?: boolean,
    value: string,
    error?: boolean,
    placeholder?: string,
    onChange?: (value: string) => void
    validatedInputProps: ValidatedInputProps
}

export const EditableField = (
    {
        editable = false,
        value,
        error,
        placeholder,
        onChange,
        validatedInputProps: {
            valueName,
            isValid,
            invalidInputs,
            invalidInputDispatch
        }
    }: Props) => {
    if(editable)
        return <ValidatedInput
            value={value}
            error={error}
            placeholder={placeholder}
            onChange={(e: any) => {if(onChange) onChange(e.target.value)}}
            valueName={valueName}
            isValid={isValid}
            invalidInputs={invalidInputs}
            invalidInputDispatch={invalidInputDispatch}
        />
    else
        return <Typography>{value}</Typography>
}