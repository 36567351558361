import * as React from "react";
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {DesignerContext} from "../../DesignerContextProvider";
import * as yamlMapper from "js-yaml";
import {YAMLException} from "js-yaml";
import {Typography} from "@matillion/component-library";
import DatabaseStateTI from "../../../../interfaces/DatabaseState-ti";
import {DatabaseState as DbState} from "../../../../interfaces/DatabaseState";
import {createCheckers} from "ts-interface-checker";
import '../../DesignerInterface.css'
import {isDesiredStateValid} from "../../../../common/DesiredStateValidationSF";
import 'codemirror/mode/javascript/javascript';
import {DBFabYamlEditor} from "./DBFabYamlEditor";

const {DatabaseState} = createCheckers(DatabaseStateTI);

interface Props {
    setYamlValid: Dispatch<SetStateAction<boolean>>
}

export const YamlView = ({setYamlValid}: Props) => {
    const {state, setState} = useContext(DesignerContext)!
    const [prevState, setPrevState] = useState(state);
    const [yaml, setYaml] = useState<string | undefined>(undefined)
    const [yamlError, setYamlError] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (state !== undefined) {
            setYaml(yamlMapper.dump(state, {forceQuotes: true}))
            setPrevState(state)
        }
        else setYaml(undefined)
    }, [state, setYaml, setYamlValid])

    useEffect(() => {
        if (yaml !== undefined && state === prevState)
            try {
                // check for error in yaml
                const newState = yamlMapper.load(yaml) as DbState
                DatabaseState.check(newState)
                const validity = isDesiredStateValid(newState)
                if(!validity.valid) throw new Error(validity.message)
                // update state
                if (JSON.stringify(state) !== JSON.stringify(newState)) setState(newState)
                setYamlError(undefined)
                setYamlValid(true)
            } catch (e) {
                if(e instanceof YAMLException || e instanceof Error) {
                    setYamlError(e.message)
                    setYamlValid(false)
                }
            }
    }, [yaml, state, setState, prevState, setYamlValid])

    const errorMessage = !yamlError ? null : <>
        <Typography format="bcl" weight="bold">Error in YAML Syntax</Typography><br/>
        {yamlError.split('\n').map((line) => <Typography>{line}</Typography>)}
    </>

    if (yaml === undefined) return <></>
    else return (
        <div style={{height: "100%", display: "flex", flexDirection: "row", border: (yamlError ? "2px solid red" : undefined)}}>
            <div style={{height: "100%", flexGrow: 1}}>
                <DBFabYamlEditor
                    value={yaml}
                    onChange={(value) => setYaml(value)}
                />
            </div>
            <div style={{flexShrink: 1}}>
                {errorMessage}
            </div>
        </div>
    )
}


