import {Dispatch, SetStateAction, useContext, useEffect, useReducer, useState} from "react";
import {Button, LoadingSpinner, Table as T, Toaster, Typography} from '@matillion/component-library'
import {DesignerContext} from "../../DesignerContextProvider";
import {Checkbox} from "@mui/material";
import {ApiError} from "../../../../api/ApiError";
import {useApi} from "@matillion/octo-react-util";
import Api from "../../../../api/Api";

const {Table, TableHead, TableBody, TableRow, TableCell} = T

type Action = { type: 'alter', schemaName: string } | {type: 'reset'} | {type: 'selectAll'}

export const IgnoreSchemaSelector = ({setIgnoreSchemas}: {setIgnoreSchemas: Dispatch<SetStateAction<string[] | undefined>>}) => {
    const [loading, setLoading] = useState(true)
    const [schemas, setSchemas] = useState<string[]>()
    const [ignoreSchemas, ignoreSchemasDispatch] =
        useReducer<(state: string[], action: Action) => string[]>(ignoreSchemasReducer, []);
    const [tableBody, setTableBody] = useState<JSX.Element>()
    const api = useApi<Api>()
    const {envs} = useContext(DesignerContext)!
    const { makeToast } = Toaster.useToaster()

    function ignoreSchemasReducer(state: string[], action: Action) {
        switch (action.type) {
            case 'alter':
                if(state.includes(action.schemaName))
                    return state.filter((item) => (item !== action.schemaName))
                else
                    return state.concat(action.schemaName)
            case 'reset':
                return []
            case 'selectAll':
                if(schemas) return [...schemas]
                else return []
        }

    }

    useEffect(() => {
        if(schemas === undefined) setIgnoreSchemas(undefined)
        else setIgnoreSchemas(ignoreSchemas)
    }, [schemas, ignoreSchemas, setIgnoreSchemas])

    useEffect(() => {
        async function getSchemas() {
            try {
                setLoading(true)
                if (envs.selected) {
                    setSchemas(await api.getSchemaNames(envs.selected.envName))
                }
            } catch (e) {
                if(e instanceof ApiError) {
                    makeToast({
                        title: e.message, type: "error", action: {
                            text: "Try Again",
                            onClick: () => getSchemas()
                        }
                    })
                }
                setSchemas(undefined)
            } finally {
                setLoading(false)
            }
        }
        ignoreSchemasDispatch({type: 'reset'})
        getSchemas()
    }, [envs.selected, api, makeToast])

    useEffect(() => {
        if(schemas) setTableBody(<TableBody>
            {schemas ? schemas.map((value: string) =>
                <TableRow>
                    <TableCell width="11">
                        {value}
                    </TableCell>
                    <TableCell width="1">
                        <Checkbox
                            checked={ignoreSchemas.includes(value)}
                            onChange={() => {ignoreSchemasDispatch({type: 'alter', schemaName: value})}}
                        />
                    </TableCell>
                </TableRow>) : <></>}
        </TableBody>)
    }, [schemas, ignoreSchemas])

    if (loading) return <LoadingSpinner/>
    else if (schemas === undefined) return <Typography>This environment is empty so cannot be imported</Typography>
    else return (
        <>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <Typography>Select all schemas you want to ignore</Typography>
                <Button
                    size={"sm"}
                    alt={"blue"}
                    text={(schemas.length === ignoreSchemas.length) ? "Deselect ALL" : "Select All"}
                    onClick={() => (schemas.length === ignoreSchemas.length) ?
                        ignoreSchemasDispatch({type: 'reset'}) :
                        ignoreSchemasDispatch({type: 'selectAll'})
                    }
                />
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell  width="11">
                            Schema
                        </TableCell>
                        <TableCell width="1">
                            Ignore?
                        </TableCell>
                    </TableRow>
                </TableHead>
                {tableBody}
            </Table>
        </>
    )
}