import "beautiful-react-diagrams/styles.css";
import Diagram from "beautiful-react-diagrams";
import {Coord, DatabaseState, Schema, Table} from "../../../../interfaces/DatabaseState";
import {DiagramSchema, Node as DiagramNode} from "beautiful-react-diagrams/@types/DiagramSchema";

import styled from "@emotion/styled";
import {useContext, useEffect, useState} from "react";
import {Node} from "./Node";
import {DesignerContext} from "../../DesignerContextProvider";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";
import {isEqual} from "lodash";

const CanvasContainer = styled.div`
  flex-grow: 1;
  .bi.bi-diagram {
    box-shadow: none;
    background: white;
    border-radius: 0;
    border: 0;
    border-left: 2px solid #e5e5e5;
  }
`;

export function Canvas() {
    const { state, setState, schemas, tables } = useContext(DesignerContext)!
    const [diagramSchema, setDiagramSchema] = useState<DiagramSchema<Table>>({
        nodes: [],
        links: []
    });
    const [prevSchemaState , setPrevSchemaState] = useState<Schema | undefined>()

    const onRemoveTable = (table: Table, state: DatabaseState) => {
        if(state !== null) {
            const nextState = produce(state, (draft) => {
                if(schemas.selectedIndex !== undefined) {
                    const index = draft.schemas[schemas.selectedIndex].tables.findIndex(
                        (x) => x.name === table.name
                    );
                    draft.schemas[schemas.selectedIndex].tables.splice(index, 1);
                    draft.schemas.forEach((schema) => {
                        schema.tables.forEach((table) => {
                            table.foreignKeys = table.foreignKeys.filter((fk) => {
                                    return fk.referenceTable.schema !== schemas.selected!.name &&
                                    fk.referenceTable.table !== draft.schemas[schemas.selectedIndex!].tables[index].name
                                }
                            )
                        })
                    })
                }
            });
            setState(nextState);
            tables.setSelectedIndex(0);
        }
    };

    // Update the diagram schema and update state with new coords
    // The bellow is structured in a way to improve responsiveness of canvas.
    // Canvas responsiveness is improved by only calling setState() when the user has stopped moving the table
    function onSchemaChange(schema: DiagramSchema<Table>) {
        setDiagramSchema({
            nodes: schema.nodes || diagramSchema.nodes,
            links: schema.links || diagramSchema.links
        })
        async function saveNewCoords(oldCoords: Coord[]) {
            setTimeout(() => {
                let coords = diagramSchema.nodes.map((node) => {
                    return {
                        x: node.coordinates[0],
                        y: node.coordinates[1]
                    }
                })
                if(isEqual(coords, oldCoords)) {
                    const nextState = produce(state, (draft: WritableDraft<DatabaseState>) => {
                        diagramSchema.nodes.forEach((node) => {
                            if (schemas.selectedIndex !== undefined) {
                                let index =
                                    draft.schemas[schemas.selectedIndex].tables.findIndex((table) => node.id === table.name)
                                if (index !== -1)
                                    draft.schemas[schemas.selectedIndex].tables[index].guiCoord =
                                        {
                                            x: node.coordinates[0],
                                            y: node.coordinates[1]
                                        }
                            }
                        })
                    });
                    setState(nextState);
                }
            }, 500)
        }
        let coords = diagramSchema.nodes.map((node) => {
            return {
                x: node.coordinates[0],
                y: node.coordinates[1]
            }
        })
        saveNewCoords(coords)
    }

    function schemaNeedsUpdating(prevSchemaState?: Schema, currentSchemaState?: Schema): boolean {
        if(prevSchemaState === null && currentSchemaState !== null) return true
        const prevNoCoords = produce(prevSchemaState, (draft) => {
            draft?.tables.forEach((table: Table) => {
                table.guiCoord = undefined
            })
        });
        const currentNoCoords = produce(currentSchemaState, (draft) => {
            draft?.tables.forEach((table: Table) => {
                table.guiCoord = undefined
            })
        });
        return !isEqual(prevNoCoords, currentNoCoords);
    }

    // Update diagram diagramSchema when state changes
    // NOTE: does not update the diagram diagramSchema if the only change are in links or coordinates because that is handled
    //       by another function
    useEffect(() => {
        if (schemaNeedsUpdating(prevSchemaState, schemas.selected)) {
            setPrevSchemaState(schemas.selected)
            let newSchema
            if (schemas.selected === undefined) newSchema = {nodes: []}
            else newSchema = schemas.selected.tables.reduce<DiagramSchema<Table>>(
                (accum, table, index) => {
                    const x = table?.guiCoord?.x ? table?.guiCoord?.x : 0
                    const y = table?.guiCoord?.y ? table?.guiCoord?.y : 0
                    accum.nodes.push({
                        id: table.name,
                        content: (
                            <Node
                                table={table}
                                onSelectTable={() => {
                                    tables.setSelectedIndex(index);
                                }}
                                onRemoveTable={onRemoveTable}
                            />
                        ),
                        coordinates: [x, y],
                        data: table,
                        render: CustomRender,
                    });
                    table.foreignKeys.forEach((fk) => {
                        if(fk.referenceTable.table && fk.referenceTable.schema === schemas.selected?.name &&
                            schemas.selected?.tables.some(
                                (table) => table.name === fk.referenceTable.table
                            )) {
                            let link = {
                                input: table.name,
                                output: fk.referenceTable.table,
                                label: `${table.name}.[${fk.key.join()}] -> 
                                ${fk.referenceTable.table}[${fk.referenceTableColumns.join()}]`,
                                readonly: true
                            }
                            if (accum.links) accum.links.push(link)
                            else accum.links = [link]
                        }
                    })
                    return accum;
                },
                {nodes: [], links: []}
            );
            setDiagramSchema(newSchema);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schemas.selected]);

    return (
        <CanvasContainer>
            {state === null || schemas.selectedIndex === undefined ? <></> :
                <Diagram
                    schema={diagramSchema as DiagramSchema<unknown>}
                    onChange={onSchemaChange as (schema: DiagramSchema<unknown>) => void}
                />
            }

        </CanvasContainer>
    )
}


const CustomRender = ({
                          inputs,
                          outputs,
                          content,
                      }: Omit<DiagramNode<Table>, "coordinates">) => {
    return (
        <>
            {content}
            <div className="bi-port-wrapper">
                <div className="bi-input-ports">{inputs}</div>
                <div className="bi-output-ports">{outputs}</div>
            </div>
        </>
    );
};