import "beautiful-react-diagrams/styles.css";
import "./base.scss";
import {DesignerInterface} from "./components/DesignerInterface/DesignerInterface";
import {AppBase} from "@matillion/octo-react-util";
import {Auth0ContextInterface} from "@auth0/auth0-react";
import Api from "./api/Api";

const App = () => {
    return (
        <AppBase
            apiProducer={(auth0: Auth0ContextInterface, orgId: string) => new Api(auth0, orgId)}
            applicationName={"Database Fabricator"}
            appPropsLocations={[{name: "serviceLocationConfig", endpoint: "api/serviceLocationConfig"}]}
        >
            <DesignerInterface/>
        </AppBase>
    )
}

export default App;
