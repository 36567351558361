import CloseIcon from "@mui/icons-material/Close";
import {DataGrid} from "@matillion/component-library";
import {useContext} from "react";
import {DesignerContext} from "../../DesignerContextProvider";
import produce from "immer";
import {DatabaseState, ForeignKeysConstraint} from "../../../../interfaces/DatabaseState";
import {Divider} from "@mui/material";
import {AddForeignKeyButton} from "./AddForeignKeyButton";

export const ForeignKeyTab = () => {
    let {state, setState, schemas, tables} = useContext(DesignerContext)!

    const removeForeignKey = (state: DatabaseState, foreignKey: ForeignKeysConstraint) => {
        const nextState = produce(state, (draft) => {
            if (schemas.selectedIndex !== undefined && tables.selectedIndex !== undefined) {
                let table = draft.schemas[schemas.selectedIndex].tables[tables.selectedIndex]
                table.foreignKeys = table.foreignKeys.filter((fk) => fk.name !== foreignKey.name)
            }
        })
        setState(nextState)
    }

    if (!tables.selected) return null
    else return (
        <>
            <DataGrid
                columns={[
                    {
                        key: 'fkName',
                        title: 'Foreign Key Name',
                        sortable: false,
                    },
                    {
                        key: 'key',
                        title: 'Key',
                        sortable: false,
                    },
                    {
                        key: 'referenceTable',
                        title: 'Reference Table',
                        sortable: false,
                    },
                    {
                        key: 'referenceTableColumns',
                        title: 'Reference Table Columns',
                        sortable: false,
                    },
                    {
                        key: 'remove',
                        title: 'Remove',
                        sortable: false,
                        as: CloseIcon,
                    }
                ]}
                rows={
                    tables.selected.foreignKeys.map((foreignKey) => ({
                        fkName: foreignKey.name,
                        key: foreignKey.key.join(", "),
                        referenceTable: `${foreignKey.referenceTable.schema}.${foreignKey.referenceTable.table}`,
                        referenceTableColumns: foreignKey.referenceTableColumns.join(", "),
                        remove: {
                            color: "error",
                            fontSize: "large",
                            onClick: () => {
                                if (state) removeForeignKey(state, foreignKey)
                            }
                        },
                    }))
                }
            />
            <Divider/>
            <AddForeignKeyButton />
        </>
    )
}