import React, {Dispatch, FormEvent, useEffect, useMemo, useReducer, useState} from "react";
import {Input, Typography} from "@matillion/component-library";
import {isValidDbFabName} from "../../common/InputValidation";
import {v4 as uuidv4} from 'uuid';
import {Validity} from "../../interfaces/Validity";

export interface ValidatedInputProps {
    valueName?: string
    isValid?: (input: string) => Validity
    invalidInputs?: string[]
    invalidInputDispatch?: Dispatch<InvalidInputDispatchAction>
    onChange?: (event: FormEvent<HTMLInputElement>) => void
    [otherProps:string]: any
}

export const ValidatedInput = (
    {
        valueName,
        isValid = isValidDbFabName,
        invalidInputDispatch,
        onChange: propsOnChange,
        invalidInputs = [''],
        ...otherProps
    }: ValidatedInputProps) => {
    const [errorMessage, setErrorMessage] = useState<string|undefined>()
    const uuid = useMemo(() => uuidv4(), []);

    function checkValidity(value: string) {
        let validity = isValid(value)
        if(validity.valid && invalidInputs?.includes(value))
            validity = {valid: false, message: valueName ? `${valueName} '${value}' already exists` : "Invalid Input"}
        setErrorMessage(validity.message)
        if(invalidInputDispatch) {
            invalidInputDispatch({uuid:uuid, valid: validity.valid, reset: false})
        }
    }

    const onChange = (event: FormEvent<HTMLInputElement>) => {
        let value = event.currentTarget.value
        checkValidity(value)
        if(propsOnChange) propsOnChange(event)
    }

    // check validity on mount if value passed in props else assume invalid
    useEffect(() => {
        if(otherProps.value)
            checkValidity(otherProps.value)
        else if(invalidInputDispatch)
            invalidInputDispatch({uuid: uuid, valid: false, reset: false})
        // eslint-disable-next-line
    }, [])

    const error = otherProps.error || errorMessage
    return <>
        <Input error={error} onChange={onChange} {...otherProps}/>
        {errorMessage ? <Typography>{errorMessage}</Typography> : null}
    </>
}

export function useSharedInputValidity() {
    const [invalidInputUuids, invalidInputDispatch]
        = useReducer<(uuids: string[], action: InvalidInputDispatchAction) => string[]>(invalidInputReducer, [])
    const [allInputsValid, setAllInputsValid] = useState(false)

    function invalidInputReducer(uuids: string[], action: InvalidInputDispatchAction) {
        if(action.reset) return []
        else if(action.valid) {
            return uuids.filter((uuid) => {
                return uuid !== action.uuid
            })
        } else {
            return [...uuids, action.uuid]
        }
    }

    useEffect(() => {
        setAllInputsValid(invalidInputUuids.length === 0)
    }, [invalidInputUuids, setAllInputsValid])

    return {allInputsValid: allInputsValid, invalidInputDispatch: invalidInputDispatch}
}

type InvalidInputDispatchAction = {
    uuid: string,
    valid: boolean
    reset: false
} | {reset: true}