import {useContext, useState} from "react";
import {Button, Modal, Toaster, Typography} from "@matillion/component-library";
import {DesignerContext} from "../../DesignerContextProvider";
import Api from "../../../../api/Api";
import {NotSavedModal} from "./NotSavedModal";
import {ApiError} from "../../../../api/ApiError";
import {useApi} from "@matillion/octo-react-util";

export default function FindPlanButton() {
    const [loading, setLoading] = useState(false);
    const [planModalVisible, setPlanModalVisible] = useState(false)
    const [fileNotSavedModalVisible, setFileNotSavedModalVisible] = useState(false)
    const [plan, setPlan] = useState('')
    const {fileNames, state, savedState, envs} = useContext(DesignerContext)!
    const { makeToast } = Toaster.useToaster()
    const api = useApi<Api>()

    let planModal = planModalVisible ?
        <Modal size={"large"} onCancel={()=>{setPlanModalVisible(false); setPlan('')}}>
            <Typography format="bcl" weight="bold" children={"Plan"}/>
            {plan.split('\n').map((line) => <p>{line}</p>)}
        </Modal>
        : <></>

    let fileNotSavedModal = <NotSavedModal
        successAction={() => {setFileNotSavedModalVisible(false); findPlan()}}
        cancelAction={() => setFileNotSavedModalVisible(false)}
        visible={fileNotSavedModalVisible}
    />

    async function findPlan() {
        async function nestedFindPlan(envName: string, fileName: string) {
            try {
                setLoading(true);
                let plan = await api.findPlan(envName, fileName)
                setPlan(plan)
                setPlanModalVisible(true)
            } catch (e) {
                if (e instanceof ApiError) {
                    makeToast({
                        title: e.message, type: "error", action: {
                            text: "Try Again",
                            onClick: () => nestedFindPlan(envName, fileName)
                        }
                    })
                }
            } finally {
                setLoading(false);
            }
        }
        if (envs.selected !== undefined && fileNames.selected !== undefined)
            nestedFindPlan(envs.selected.envName, fileNames.selected)
    }

    return (
        <>
            {planModal}
            {fileNotSavedModal}
            <Button
                waiting={loading}
                disabled={fileNames.selected === undefined || envs.selected === undefined}
                size="sm"
                onClick={() => {
                    if(state !== savedState) setFileNotSavedModalVisible(true)
                    else findPlan()
                }}
                text="Calculate Plan"
            />
        </>
    );
}
