import {FileSelector} from "./FileSelector/FileSelector";
import {SchemaSelector} from "./SchemaSelector";
import {SaveControls} from "./SaveControls";
import {DeploymentControls} from "./deploy/DeploymentControls";
import {Divider, List, ListItem} from "@mui/material";
import {TableControls} from "./Table/TableControls";

export const SideBar = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "320",
                padding: 16,
                borderRight: "1px solid #e6e6e6"
            }}
        >
            <List sx={listStyle} component="nav" aria-label="mailbox folders">
                <ListItem><FileSelector/></ListItem><Divider/>
                <ListItem><SchemaSelector/></ListItem><Divider/>
                <ListItem><TableControls/></ListItem><Divider/>
                <ListItem><DeploymentControls/></ListItem><Divider/>
                <ListItem><SaveControls/></ListItem>
            </List>
        </div>
    )
}

const listStyle = {
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
};