import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {ApiError} from "../api/ApiError";
import {Toaster} from "@matillion/component-library";

export interface UpdatingSelection<T> {
    selection: T[] | undefined
    addItem: (item: T) => void
    removeItem: (item: T) => void
    selected: T | undefined
    setSelectedItem: (item: T | undefined) => void
    selectedIndex: number | undefined
    setSelectedIndex: Dispatch<SetStateAction<number | undefined>>
    loading: boolean
}

export function useUpdatingSelection<T>(updatingFun: () => (Promise<T[] | undefined> | T[] | undefined), deps: any[]): UpdatingSelection<T> {
    let [selection, setSelection] = useState<T[] | undefined>()
    let [selected, setSelected] = useState<T | undefined>()
    let [loading, setLoading] = useState(false)
    let [selectedIndex, setSelectedIndex] = useState<number>()
    const { makeToast } = Toaster.useToaster()

    /**
     * Updates the selection selection
     */
    useEffect(() => {
        async function updateSelection() {
            try {
                setLoading(true)
                if(updatingFun instanceof (async () => {}).constructor) setSelection(undefined)
                const newSelection = await updatingFun()
                setSelection(newSelection)
            } catch (e) {
                if (e instanceof ApiError) {
                    makeToast({
                        title: e.message, type: "error", action: {
                            text: "Try Again",
                            onClick: () => updateSelection()
                        }
                    })
                } else {
                    makeToast({
                        title: "An unknown error has occurred.", type: "error", action: {
                            text: "Reload Page",
                            onClick: () => window.location.reload()
                        }
                    })
                }
            }
            setLoading(false)
        }
        updateSelection()
    },
        // eslint-disable-next-line
        deps)

    function addItem(item: T) {
        if(selection !== undefined) setSelection(selection.concat(item))
        else setSelection([item])
    }

    function removeItem(item: T) {
        if(selection !== undefined) {
            const array = [...selection]
            const index = array.indexOf(item, 0)
            if (index !== -1) {
                array.splice(index, 1)
                setSelection(array)
            }
        }
    }

    /**
     * Sets [selectedIndex] to the object that matches [item] and undefined if no match.
     * @param item The item in the collection you want to select.
     */
    function setSelectedItem(item: T | undefined) {
        if(selection !== undefined && item !== undefined) {
            let index = selection.indexOf(item)
            if(index !== -1) setSelectedIndex(index)
            else setSelectedIndex(undefined)
        } else setSelectedIndex(undefined)
    }

    /**
     * Updates 'selected' when the index or 'selection' changes
     */
    useEffect(() => {
        if(selection !== undefined && selectedIndex !== undefined && selectedIndex < selection.length) {
            setSelected(selection[selectedIndex])
        } else {
            setSelected(undefined)
        }
    }, [setSelected, selectedIndex, selection])

    return {
        selection: selection,
        addItem: addItem,
        removeItem: removeItem,
        selected: selected,
        setSelectedItem: setSelectedItem,
        selectedIndex: selectedIndex,
        setSelectedIndex: setSelectedIndex,
        loading: loading
    }
}