import {SideBar} from "./SideBar/SideBar";
import {DesignerContextProvider} from "./DesignerContextProvider";
import './DesignerInterface.css';
import {DesignerBody} from "./DesignerBody/DesignerBody";

export const DesignerInterface = () => {
    return (
        <DesignerContextProvider>
            <div style={{height: "100%", width: "100%", display: "flex", flexDirection: "row"}}>
                <SideBar/>
                <div style={{flexGrow: 1}}>
                    <DesignerBody/>
                </div>
            </div>
        </DesignerContextProvider>
    )
}