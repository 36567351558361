import {Button, Modal, Toaster, Typography} from "@matillion/component-library";
import React, {FormEvent, useContext, useMemo, useState} from "react";
import {WarehouseProviderSelector} from "../../WarehouseProviderSelector";
import {DesignerContext} from "../../../DesignerContextProvider";
import {EnvironmentMetadata} from "../../../../../interfaces/EnvironmentMetadata";
import {ApiError} from "../../../../../api/ApiError";
import {useSharedInputValidity, ValidatedInput} from "../../../../common/ValidatedInput";
import {isValidSpec1} from "../../../../../common/InputValidation";
import {MfeContainer, useApi, useAppProps} from "@matillion/octo-react-util";
import Api from "../../../../../api/Api";

export const AddEnvironmentButton = () => {
    let api = useApi<Api>()
    let {envs} = useContext(DesignerContext)!
    let [envModalVisible, setEnvModalVisible] = useState(false)
    let [envWarehouseProvider, setEnvWarehouseProvider] = useState<string>('')
    let [envName, setEnvName] = useState('')
    let [databaseName, setDatabaseName] = useState('')
    let [accountId, setAccountId] = useState('')
    let [user, setUser] = useState('')
    let [password, setPassword] = useState('')
    let [warehouse, setWarehouse] = useState('')
    let [addingEnv, setAddingEnv] = useState(false)
    const { makeToast } = Toaster.useToaster()
    const {allInputsValid, invalidInputDispatch} = useSharedInputValidity()
    const props = useAppProps()

    const newEnvDataInput = useMemo(() => {
        if(envWarehouseProvider === '') return <></>
        else if(envWarehouseProvider === 'Snowflake') return (
            <div style={{display: "flex", flexDirection: "column", rowGap: "10px"}}>
                <ValidatedInput
                    valueName={"Environment Name"}
                    placeholder="Environment Name..."
                    onChange={
                        (e: FormEvent<HTMLInputElement>) => {setEnvName(e.currentTarget.value)}
                    }
                    invalidInputDispatch={invalidInputDispatch}
                    invalidInputs={envs.selection?.map((env) => env.envName)}
                />
                <ValidatedInput
                    placeholder="Database Name..."
                    onChange={
                        (e: FormEvent<HTMLInputElement>) => {setDatabaseName(e.currentTarget.value)}
                    }
                    invalidInputDispatch={invalidInputDispatch}
                />
                <ValidatedInput
                    placeholder="Account Identifier..."
                    onChange={
                        (e: FormEvent<HTMLInputElement>) => {setAccountId(e.currentTarget.value)}
                    }
                    isValid={isValidSpec1}
                    invalidInputDispatch={invalidInputDispatch}
                />
                <ValidatedInput
                    placeholder="User..."
                    onChange={
                        (e: FormEvent<HTMLInputElement>) => {setUser(e.currentTarget.value)}
                    }
                    isValid={isValidSpec1}
                    invalidInputDispatch={invalidInputDispatch}
                />
                <MfeContainer
                    hostname={props.serviceLocationConfig.secretServiceUiLocation}
                    name={"SecretSelector"}
                    mfeProps={{setSecretName: setPassword}}
                />
                <ValidatedInput
                    placeholder="Warehouse..."
                    onChange={
                        (e: FormEvent<HTMLInputElement>) => {setWarehouse(e.currentTarget.value)}
                    }
                    isValid={isValidSpec1}
                    invalidInputDispatch={invalidInputDispatch}
                />
            </div>
        )
    }, [envWarehouseProvider, envs.selection, invalidInputDispatch, props])

    function resetState() {
        setEnvModalVisible(false)
        setEnvWarehouseProvider('')
        setEnvName('')
        setDatabaseName('')
        setAccountId('')
        setUser('')
        setPassword('')
        setWarehouse('')
        invalidInputDispatch({reset: true})
    }

    async function addEnv(envMetadata: EnvironmentMetadata) {
        try {
            setAddingEnv(true)
            await api.addEnv(envMetadata)
            makeToast({title: `Successfully added environment '${envMetadata.envName}'`, type: "success"})
            envs.addItem(envMetadata)
            resetState()
        } catch (e) {
            if (e instanceof ApiError) {
                makeToast({
                    title: e.message, type: "error", action: {
                        text: "Try Again",
                        onClick: () => addEnv(envMetadata)
                    }
                })
            }
        } finally {
            setAddingEnv(false)
        }
    }

    let newEnvModal = envModalVisible ?
        <Modal onCancel={()=>{resetState()}} style={{display: "flex", flexDirection: "column", rowGap: "10px"}}>
            <Typography format="bcl" weight="bold">Add New Environment</Typography>
            <WarehouseProviderSelector setWarehouseProvider={setEnvWarehouseProvider} />
            {newEnvDataInput}
            <Button
                disabled={!allInputsValid || envWarehouseProvider === ''}
                waiting={addingEnv}
                iconAlign="right"
                onClick={() => {
                    addEnv(
                        {
                            warehouseProvider: envWarehouseProvider,
                            envName: envName,
                            dbName: databaseName,
                            sfAccountIdentifier: accountId,
                            sfUser: user,
                            password: password,
                            sfWarehouse: warehouse,
                        }
                    )
                }}
                size="md"
                text="Add New Environment"
            />
        </Modal>
        : <></>

    return (
        <>
            <Button
                size="sm"
                alt="primary"
                onClick={()=>{setEnvModalVisible(true)}}
            >
                Add New Environment
            </Button>
            {newEnvModal}
        </>
    )
}