import {Button, Toaster} from "@matillion/component-library";
import {useContext, useEffect, useState} from "react";
import {DesignerContext} from "../../../DesignerContextProvider";
import {ApiError} from "../../../../../api/ApiError";
import Api from "../../../../../api/Api";
import {useApi} from "@matillion/octo-react-util";

export const UnlockEnvironmentButton = () => {
    const [loading, setLoading] = useState(false)
    const [locked, setLocked] = useState(false)
    const { envs } = useContext(DesignerContext)!
    const api = useApi<Api>()
    const { makeToast } = Toaster.useToaster()

    useEffect(() => {
        async function getEnvLockState() {
            if(envs.selected) {
                try {
                    setLoading(true)
                    const locked = await api.getEnvironmentLock(envs.selected.envName)
                    setLocked(locked)
                } catch (e) {
                    if (e instanceof ApiError) {
                        makeToast({title: e.message, type: "error", action: {text: "Try Again", onClick: () => getEnvLockState()}})
                    }
                } finally {
                    setLoading(false)
                }
            }
        }
        getEnvLockState()
    }, [envs.selected, api, makeToast])

    async function unlockEnv(envName: string) {
        try {
            await api.unlockEnvironment(envName)
            setLocked(false)
        } catch (e) {
            if (e instanceof ApiError) {
                makeToast({title: e.message, type: "error", action: {
                    text: "Try Again", onClick: () => unlockEnv(envName)
                }})
            }
        }
    }

    return (
        <Button
            size="sm"
            alt="red"
            onClick={() => {
                if(envs.selected) unlockEnv(envs.selected.envName)
            }}
            waiting={loading}
            disabled={envs.selected === undefined || !locked}
            text={"Unlock Environment"}
        />
    )
}