import {FormEvent, useContext, useState} from "react";
import {DesignerContext} from "../DesignerContextProvider";
import {AutoComplete, Button, Modal, Typography} from "@matillion/component-library";
import produce from "immer";
import {v4 as uuidv4} from "uuid";
import {AutoCompleteItem} from "../../../interfaces/MclUtil";
import {useSharedInputValidity, ValidatedInput} from "../../common/ValidatedInput";

export const SchemaSelector = () => {
    let {fileNames, schemas, state, savedState, resetDesigner} = useContext(DesignerContext)!
    let [newSchemaModalVisible, setNewSchemaModalVisible] = useState(false)
    let [newSchemaName, setNewSchemaName] = useState('')
    const {allInputsValid, invalidInputDispatch} = useSharedInputValidity()

    function createNewSchema() {
        if (state !== null) {
            const nextState = produce(state, (draft) => {
                draft.schemas.push({
                    uuid: uuidv4(),
                    dataRetentionTimeInDays: 1,
                    managedAccess: true,
                    name: newSchemaName,
                    tables: [],
                    transient: false,
                    comment: ""
                });
            });
            resetDesigner({
                fileNameIndex: fileNames.selectedIndex,
                state: nextState,
                savedState: savedState,
                schemaIndex: nextState.schemas.length-1,
                tableIndex: 0
            })
            setNewSchemaModalVisible(false)
            invalidInputDispatch({reset: true})
        }
    }

    function onRemoveSchema() {
        if (state !== null) {
            const nextState = produce(state, (draft) => {
                if(schemas.selectedIndex !== undefined) draft.schemas.splice(schemas.selectedIndex, 1);
            });
            let nextSchemaIndex
            if(nextState.schemas.length >= 1) nextSchemaIndex = 0
            else nextSchemaIndex = undefined
            resetDesigner({
                fileNameIndex: fileNames.selectedIndex,
                state: nextState,
                savedState: savedState,
                schemaIndex: nextSchemaIndex,
                tableIndex: 0
            })
        }
    }

    let newSchemaModal = newSchemaModalVisible ?
        <Modal
            onCancel={() => {
                setNewSchemaModalVisible(false)
                invalidInputDispatch({reset: true})
            }}
            style={{display: "flex", flexDirection: "column", rowGap: "10px"}}
        >
            <Typography format="bcl" weight="bold">Create New Schema</Typography>
            <ValidatedInput
                valueName={"Schema Name"}
                placeholder="Schema Name..."
                onChange={
                    (event: FormEvent<HTMLInputElement>) => {setNewSchemaName(event.currentTarget.value)}
                }
                invalidInputDispatch={invalidInputDispatch}
                invalidInputs={schemas.selection?.map((schema)=>schema.name)}
            />
            <Button
                disabled={!allInputsValid}
                iconAlign="right"
                onClick={() => {
                    createNewSchema()
                }}
                size="md"
                text="Create New Schema"
            />
        </Modal>
        : <></>

    const componentDisabled = fileNames.selected === undefined

    return (
        <div style={{display: "flex", flexDirection: "column", rowGap: "10px"}}>
            <Typography format={"bcl"} style={componentDisabled ? {color: "grey"} : {}}>Schemas</Typography>
            <AutoComplete
                loading={schemas.loading}
                disabled={componentDisabled || !schemas.selection?.length}
                availableItems={schemas.selection?.map((opt, index) => ({id: index, name: opt.name,})) ?? []}
                value={schemas.selected ? {id: schemas.setSelectedIndex, name: schemas.selected.name} : null}
                placeholder="Choose Schema"
                onChange={({target: {value: newValue},}: { target: AutoCompleteItem; }) => {
                    if (newValue) {
                        resetDesigner({
                            fileNameIndex: fileNames.selectedIndex,
                            state: state,
                            savedState: savedState,
                            schemaIndex: Number(newValue?.id),
                            tableIndex: 0
                        })
                    }
                }}
            />
            <Button
                disabled={componentDisabled}
                size="sm"
                alt="blue"
                onClick={() => {
                    setNewSchemaName('');
                    setNewSchemaModalVisible(true)
                }}
                text={"Create New Schema"}
            />
            <Button
                disabled={componentDisabled || schemas.selected === undefined}
                onClick={() => {onRemoveSchema()}}
                size="sm"
                alt={"red"}
                text="Delete Schema"
            />
            {newSchemaModal}
        </div>
    );
}