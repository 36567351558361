import {Button} from "@matillion/component-library";
import {useState} from "react";
import {EnvironmentControlsModal} from "./EnvironmentControlsModal";

export const ShowEnvironmentControlsButton = () => {
    const [visible, setVisible] = useState(false)

    return (
        <>
            <Button
                size="sm"
                alt="blue"
                onClick={() => {
                    setVisible(true)
                }}
                text={"Environment Controls"}
            />
            <EnvironmentControlsModal visible={visible} setVisible={setVisible}/>
        </>
    )
}