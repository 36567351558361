import {useContext} from "react";
import {DesignerContext} from "../../DesignerContextProvider";
import {Typography} from "@matillion/component-library";
import FindPlanButton from "./FindPlanButton";
import {DeployButton} from "./DeployButton";
import {EnvironmentSelector} from "../EnvironmentSelector";
import {ShowEnvironmentControlsButton} from "./environmentControls/ShowEnvironmentControlsButton";

export const DeploymentControls = () => {
    let {envs} = useContext(DesignerContext)!

    const componentDisabled = !envs.selection?.length

    return (
        <div style={{display: "flex", flexDirection: "column", rowGap: "10px"}}>
            <Typography format={"bcl"} style={componentDisabled ? {color: "grey"} : {}}>Deploy</Typography>
            <EnvironmentSelector/>
            <ShowEnvironmentControlsButton/>
            <FindPlanButton/>
            <DeployButton/>
        </div>)
}