import {AutoCompleteItem} from "../../../interfaces/MclUtil";
import {AutoComplete} from "@matillion/component-library";
import {useContext} from "react";
import {DesignerContext} from "../DesignerContextProvider";

interface Props {
    disabled?: boolean
}

export const EnvironmentSelector = ({disabled=false}: Props) => {
    const {envs} = useContext(DesignerContext)!

    return (
        <AutoComplete
            value={envs.selected ? {id: envs.selectedIndex, name: envs.selected.envName} : null}
            loading={envs.loading}
            disabled={disabled || !envs.selection?.length}
            availableItems={envs.selection?.map((env, index) => ({id: index, name: env.envName})) ?? []}
            placeholder="Choose Environment"
            onChange={({target: {value: newValue}}: { target: AutoCompleteItem; }) => {
                if (newValue) envs.setSelectedIndex(Number(newValue.id))
            }}
        />
    )
}