import {useContext, useState} from "react";
import {Tab, Tabs, Toaster} from "@matillion/component-library";
import {DesignerContext} from "../DesignerContextProvider";
import {TabChange} from "../../../interfaces/MclUtil";
import {YamlView} from "./YamlView/YamlView";
import {Canvas} from "./Canvas/Canvas";
import {TableProperties} from "./TableProperties/TableProperties";
import '../DesignerInterface.css';
import {Instructions} from "./Instructions";

export const DesignerBody = () => {
    const [yamlValid, setYamlValid] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const { makeToast } = Toaster.useToaster()
    const {schemas, state, fileNames} = useContext(DesignerContext)!

    function changeTabs(change: TabChange) {
        if(change.value === 1) setActiveIndex(1)
        else if(!yamlValid) makeToast({title: "YAML is invalid. Please fix before viewing GUI.", type: "error"})
        else setActiveIndex(0)
    }

    if(!state || !fileNames.selected) return <Instructions/>
    else return (
        <Tabs
            initialIndex={0}
            activeIndex={activeIndex}
            onChange={changeTabs}
            tabFormat="stretch"
            contentClassName={"TabContents"}
            className={"Tabs"}
            listClassName={"TabList"}
        >
            <Tab title="GUI Editor">
                {!schemas.selected ? <Instructions/> :
                    <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
                        <Canvas/>
                        <TableProperties/>
                    </div>
                }
            </Tab>
            <Tab title="YAML Editor">
                <YamlView setYamlValid={setYamlValid}/>
            </Tab>
        </Tabs>
    )
}