import {AutoComplete, Button, Modal, Typography} from "@matillion/component-library";
import {useContext, useEffect, useState} from "react";
import {DesignerContext} from "../../DesignerContextProvider";
import {Table} from "../../../../interfaces/DatabaseState";
import {AutoCompleteItem} from "../../../../interfaces/MclUtil";
import {ColumnSelector} from "../../SideBar/Table/ColumnSelector";
import produce from "immer";
import {v4 as uuidv4} from "uuid";

export const AddForeignKeyButton = () => {
    const {state, setState, tables, schemas} = useContext(DesignerContext)!
    const [fkModalVisible, setFKModalVisible] = useState(false)
    const [table1, setTable1] = useState<Table>()
    const [table1SelectedCols, setTable1SelectedCols] = useState<string[]>()
    const [table2, setTable2] = useState<Table>()
    const [table2SelectedCols, setTable2SelectedCols] = useState<string[]>()
    const [errorMessage, setErrorMessage] = useState<string>()

    useEffect(() => {
        if(table1SelectedCols?.length !== table2SelectedCols?.length)
            setErrorMessage("Number of columns in key for table 1 needs to match number of columns in key for table 2")
        else if(!table1SelectedCols?.length || !table2SelectedCols?.length)
            setErrorMessage("A foreign key needs at least 1 column")
        else setErrorMessage(undefined)
    }, [table1SelectedCols, table2SelectedCols])

    const addForeignKey = () => {
        const newState = produce(state, (draft) => {
            if(table2 && schemas.selectedIndex !== undefined && schemas.selected && tables.selection && table1SelectedCols && table2SelectedCols) {
                const table1Index = tables.selection.findIndex((table) => table === table1)
                draft?.schemas[schemas.selectedIndex].tables[table1Index].foreignKeys?.push({
                    name: uuidv4(),
                    key: table1SelectedCols,
                    referenceTable: {
                        schema: schemas.selected.name,
                        table: table2.name
                    },
                    referenceTableColumns: table2SelectedCols
                })
            }
        })
        setState(newState)
        closeModal()
    }

    function closeModal () {
        setTable1(undefined)
        setTable2(undefined)
        setTable1SelectedCols(undefined)
        setTable2SelectedCols(undefined)
        setFKModalVisible(false)
    }

    const fkModal = fkModalVisible ? (
        <Modal
            onCancel={() => {
                closeModal()
            }}
            size={"fullscreen"}
        >
            <Typography format="bcl" weight="bold">Add Foreign Key</Typography>
            <div style={{display: "flex", flexDirection: "row"}}>
                <div>
                    <Typography>Table 1</Typography>
                    <AutoComplete
                        availableItems={tables.selection?.map((table) =>({
                                id: table.name,
                                name: table.name
                        }))}
                        value={table1}
                        onChange={({target: {value: newValue}}: {target: AutoCompleteItem}) => {
                            setTable1(tables.selection?.find((table) => table.name === newValue?.name))
                        }}
                    />
                    {table1 !== undefined ? <ColumnSelector
                        columns={table1.columns}
                        setSelectedCols={setTable1SelectedCols}
                    /> : <></>}
                </div>
                <div>
                    <Typography>Table 2</Typography>
                    <AutoComplete
                        availableItems={tables.selection?.map((table) => ({
                                id: table.name,
                                name: table.name
                        }))}
                        value={table2}
                        onChange={({target: {value: newValue}}: {target: AutoCompleteItem}) => {
                            setTable2(tables.selection?.find((table) => table.name === newValue?.name))
                        }}
                    />
                    {table2 !== undefined ? <ColumnSelector
                        columns={table2.columns}
                        setSelectedCols={setTable2SelectedCols}
                    /> : <></>}
                </div>
            </div>
            <Button
                disabled={errorMessage !== undefined}
                text={"Add Foreign Key"}
                onClick={addForeignKey}
            />
            <Typography>{errorMessage}</Typography>
        </Modal>
    ) : <></>

    return <>
        <Button
            disabled={schemas.selected === undefined || tables.selection?.length === 0}
            size="sm"
            text="Add Foreign Key"
            onClick={() => {
                setTable1(tables.selected)
                setFKModalVisible(true)
            }}
        />
        {fkModal}
    </>
}