import {ColumnTab} from "./ColumnTab";
import {Tab, Tabs, Typography} from "@matillion/component-library";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {FormEvent, useContext, useEffect, useState} from "react";
import {DesignerContext} from "../../DesignerContextProvider";
import {ForeignKeyTab} from "./ForeignKeyTab";
import {useSharedInputValidity, ValidatedInput} from "../../../common/ValidatedInput";
import {EditControls} from "./EditControls";
import {DatabaseState} from "../../../../interfaces/DatabaseState";
import produce from "immer";

export const TableProperties = () => {
    let {state, setState, schemas, tables} = useContext(DesignerContext)!
    const [editing, setEditing] = useState(false)
    const [newTableName, setNewTableName] = useState<string>()
    const {allInputsValid, invalidInputDispatch} = useSharedInputValidity()

    function resetEditing() {
        setNewTableName(tables.selected?.name)
        setEditing(false)
        invalidInputDispatch({reset: true})
    }

    useEffect(resetEditing, [tables.selected, invalidInputDispatch])

    function setTableName(newName: string, state: DatabaseState, schemaIndex: number, tableIndex: number) {
        const newState = produce(state, (draft) => {
            draft.schemas[schemaIndex].tables[tableIndex].name = newName
        })
        setState(newState)
    }

    return !tables.selected ? null : (
        <div style={{maxHeight: 500, overflowY: "auto", overflowX: "hidden", borderTop: "1px solid grey"}}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0 25px 0 5px",
            }}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Typography style={{paddingTop: 10}} format="tm">Table: </Typography>
                    {editing ?
                        <ValidatedInput
                            valueName={"Table Name"}
                            invalidInputs={tables.selection
                                ?.map((tbl) => tbl.name)
                                .filter((tblName) => tblName !== tables.selected?.name)}
                            invalidInputDispatch={invalidInputDispatch}
                            onChange={(event: FormEvent<HTMLInputElement>) => setNewTableName(event.currentTarget.value)}
                            value={newTableName}
                        /> :
                        <Typography style={{paddingTop: 10}} format="tm">{tables.selected.name}</Typography>
                    }
                    &nbsp;
                    <EditControls
                        editable={editing}
                        onSetEditable={() => setEditing(true)}
                        onAccept={() => {
                            if(newTableName && state && schemas.selectedIndex !== undefined && tables.selectedIndex !== undefined)
                                setTableName(newTableName, state, schemas.selectedIndex, tables.selectedIndex)
                            resetEditing()
                        }}
                        onCancel={() => {
                            resetEditing()
                        }}
                        allowAccept={allInputsValid}
                    />
                </div>
                <div
                    style={{display: "flex", flexDirection: "row", alignItems: "center"}}
                    onClick={() => tables.setSelectedIndex(undefined)}
                >
                    <ArrowDownwardIcon fontSize="large" />
                    <Typography>Close Table Properties</Typography>
                </div>
            </div>
            <Tabs initialIndex={0} tabFormat={"stretch"}>
                <Tab title="Columns">
                    <ColumnTab />
                </Tab>
                <Tab title="Foreign Key">
                    <ForeignKeyTab />
                </Tab>
            </Tabs>
        </div>
    )
};
