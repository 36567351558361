import {Button, Modal, Typography} from "@matillion/component-library";
import {FormEvent, useContext, useState} from "react";
import produce from "immer";
import {DesignerContext} from "../../DesignerContextProvider";
import {v4 as uuidv4} from 'uuid';
import {useSharedInputValidity, ValidatedInput} from "../../../common/ValidatedInput";

export const CreateNewTableButton = () => {
    let {state, setState, schemas, fileNames, savedState, resetDesigner, tables} = useContext(DesignerContext)!
    let [newTableModalVisible, setNewTableModalVisible] = useState(false)
    let [newTableName, setNewTableName] = useState('')
    const {allInputsValid, invalidInputDispatch} = useSharedInputValidity()

    let newTableModal = newTableModalVisible ?
        <Modal
            onCancel={()=>{
                setNewTableModalVisible(false)
                invalidInputDispatch({reset: true})
            }}
            style={{display: "flex", flexDirection: "column", rowGap: "10px"}}
        >
            <Typography format="bcl" weight="bold">Create New Table</Typography>
            <ValidatedInput
                valueName={"Table Name"}
                placeholder="Table Name..."
                onChange={
                    (event: FormEvent<HTMLInputElement>) => {setNewTableName(event.currentTarget.value)}
                }
                invalidInputDispatch={invalidInputDispatch}
                invalidInputs={tables.selection?.map((table)=>table.name)}
            />
            <Button
                disabled={!allInputsValid}
                iconAlign="right"
                onClick={() => {createNewTable()}}
                size="md"
                text="Create New Table"
            />
        </Modal>
        : <></>

    function createNewTable() {
        if (state !== null) {
            const nextState = produce(state, (draft) => {
                if(schemas.selectedIndex !== undefined) {
                    draft.schemas[schemas.selectedIndex].tables.push({
                        uuid: uuidv4(),
                        name: newTableName,
                        comment: "",
                        columns: [{
                            uuid: uuidv4(),
                            name: "C1",
                            typeName: "VARCHAR(16777216)",
                            unique: false,
                            nullable: true,
                            primary: false,
                        }],
                        guiCoord: {x: 0, y:0},
                        dataRetentionTimeInDays: 1,
                        foreignKeys: []
                    });
                }
            });
            setState(nextState)
            resetDesigner({fileNameIndex: fileNames.selectedIndex, state: nextState,
                savedState: savedState, schemaIndex: schemas.selectedIndex,
                tableIndex: nextState.schemas[schemas.selectedIndex!].tables.length-1});
            setNewTableModalVisible(false)
            invalidInputDispatch({reset: true})
        }
    }

    const componentDisabled = schemas.selected === undefined

    return (
        <div style={{display: "flex", flexDirection: "column", rowGap: "10px"}}>
            <Typography format={"bcl"} style={componentDisabled ? {color: "grey"} : {}}>Tables</Typography>
            <Button
                disabled={componentDisabled}
                size="sm"
                alt="blue"
                onClick={()=>{
                    setNewTableName('')
                    setNewTableModalVisible(true)
                }}
                text={"Create New Table"}
            />
            {newTableModal}
        </div>
    );
}