import {DatabaseState} from "../interfaces/DatabaseState";
import {Validity} from "../interfaces/Validity";
import {isValidDbFabName, isValidTypeSpec} from "./InputValidation";

export function isDesiredStateValid(ds: DatabaseState): Validity {
    const schemas = ds.schemas
    const schemaNames = schemas.map((sch) => sch.name)

    if((new Set(schemaNames)).size !== schemaNames.length)
        return {valid: false, message: "Schemas with duplicate names."}
    for (let schIdx = 0; schIdx < schemas.length; schIdx++) {
        const schema = schemas[schIdx]
        const validName = isValidDbFabName(schema.name)
        if(!validName.valid) {
            validName.message = `Schema ${schema.name} has an invalid name.`
            return validName
        }

        const tables = schema.tables
        const tableNames = tables.map((tbl) => tbl.name)
        if((new Set(tableNames)).size !== tableNames.length)
            return {valid: false, message: `Tables with duplicate names in schema ${schema.name}.`}
        for (let tblIdx = 0; tblIdx < tables.length; tblIdx++) {
            const table = tables[tblIdx]
            const validName = isValidDbFabName(table.name)
            if(!validName.valid) {
                validName.message = `Table ${table.name} has an invalid name.`
                return validName
            }

            const columns = table.columns
            if(columns.length === 0) return {valid: false, message: `Tables needs at least 1 column.`}
            const columnNames = columns.map((col) => col.name)
            if((new Set(columnNames)).size !== columnNames.length)
                return {valid: false, message: `Columns with duplicate names in table ${table.name}.`}
            for (let colIdx = 0; colIdx < columns.length; colIdx++) {
                const column = columns[colIdx]
                if(column.primary && (column.nullable || column.unique))
                    return {valid: false, message: `Columns ${schema.name}.${table.name}.${column.name} has an ` +
                            `invalid combination of primary, nullable and unique properties.`}
                const validName = isValidDbFabName(column.name)
                if(!validName.valid) {
                    validName.message = `Column ${schema.name}.${table.name}.${column.name} has an invalid name.`
                    return validName
                }
                const validType = isValidTypeSpec(column.typeName)
                if(!validType.valid) {
                    validType.message = `Column ${schema.name}.${table.name}.${column.name} has an invalid type.`
                    return validType
                }
            }
        }
    }

    return {valid: true}
}