import {Button, Toaster} from "@matillion/component-library";
import {useContext, useState} from "react";
import {DesignerContext} from "../../../DesignerContextProvider";
import {ApiError} from "../../../../../api/ApiError";
import {EnvironmentMetadata} from "../../../../../interfaces/EnvironmentMetadata";
import {useApi} from "@matillion/octo-react-util";
import Api from "../../../../../api/Api";

export const DeleteEnvironmentButton = () => {
    const {envs} = useContext(DesignerContext)!
    const [loading, setLoading] = useState(false)
    const api = useApi<Api>()
    const { makeToast } = Toaster.useToaster()

    async function deleteEnv(env: EnvironmentMetadata) {
        try {
            setLoading(true)
            await api.deleteEnv(env.envName)
            makeToast({title: `Successfully deleted environment ${env.envName}`, type: 'success'})
            envs.removeItem(env)
        } catch (e) {
            if (e instanceof ApiError) {
                makeToast({
                    title: e.message, type: "error", action: {
                        text: "Try Again",
                        onClick: () => deleteEnv(env)
                    }
                })
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <Button
            size="sm"
            alt="red"
            onClick={() => {
                if(envs.selected) deleteEnv(envs.selected)
            }}
            waiting={loading}
            disabled={envs.selected === undefined}
            text={"Delete Environment"}
        />
    )
}